.div-search{
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.8);
	position: fixed;
	z-index: 99999;
	display: none;
	text-align: center;

	.search-form{
		display: block;
		overflow: hidden;
		width: 50%;
		margin: auto;
	}

	.search-field,
	.search-submit{
		border: 0px !important;
		font-size: 15px;
		height: 30px;
		float: left;
		display: inline-block;
	}

	.search-field{
		background: white;
		width: 200px;
	}

	.search-submit{
		background: black;
		color: white;
		font-weight: bold;
		width: 100px;
	}

}


#search{
	width: 100%;
	height: 100%;
	position: fixed;
	background: rgba(13, 13, 13, 0.7);
	z-index: 999998;
	text-align: center;
	display: none;

	#search-container {
		position: relative;
		display: inline-block;
	}

	.close-search{
		position: relative;
		display: block;
		z-index: 99999;
		top: 190px;
		float: right;
		color: white;
		font-size: 28px !important;
		cursor: pointer;
		background-size: 100%;
		height: 30px;
		width: 30px;
		&:before,
		&:after {
			content: "";
			height: 55px;
			width: 1px;
			background: white;
			display: block;
			position: relative;
			left: 15px;
			top: 0px;
		}
		&:before {
			transform: rotate(-45deg);
		}
		&:after {
			transform: rotate(45deg);
			top: -55px;
		}
		.fa {
			color: white;
		}
	}

	form{
		margin: auto;
		margin-top: 400px;

		label{
			display: none !important;
		}

		input{
			height: 65px;
			display: inline-block;
			background: white;
			padding: 15px 20px;
			border: 0px;
			text-transform: uppercase;
			color: #000;

			&:focus{
				outline: 0 !important;
			}
		}

		input[type="submit"]{
			background: black;
			color: white;
			text-transform: uppercase;
			font-weight: 300;
		}
	}
}


/** Header **/
#header {
    width: 100%;
    background: transparent;
	position: fixed;
	top: 0;
	z-index: 15;
	transition: .4s all;
	.logo{
		&.color img{
			display: none;
		}
	}
	&.sticky{
		box-shadow: 1px 1px 10px #c6c6c6;
		background: white;
		.menu-item-has-children{
			a{
				color: #575756 !important;
			}
			ul a{
				color: white !important;
			}
			&.current-menu-item a, &.current-menu-parent a{
				color: white !important;
			}
		}
		.search{
			color: #575756;
		}
		.logo.white img{
			display: none;
		}
		.logo.color img{
			display: block;
		}
	}

	.current-menu-item, .current-menu-parent, .menu-item-has-children:hover{
		background-color: #575756 !important;
		color: white !important;
		.dropdown-toggle{
			color: white !important;
		}
		&.menu-culture{
			background-color: $color-culture !important;
		}
		&.menu-societe{
			background-color: $color-societe !important;
		}
		&.menu-scientifique{
			background-color: $color-scientifique !important;
		}
	}

	.menu-culture .dropdown-menu li{
		background-color: $color-culture !important;
	}
	.menu-societe .dropdown-menu li{
		background-color: $color-societe !important;
	}
	.menu-scientifique .dropdown-menu li{
		background-color: $color-scientifique !important;
	}

	#mobile-navigation {
		width: 100%;
		z-index: 10;
		position: absolute;
		.slicknav_menu {
			background: none;
			padding: 0;
			.slicknav_nav {
				padding: 10px 0;
				background: $color-main;
				position: absolute;
				width: 100%;
				overflow: scroll;
				li {
					&.mobile {
						display: block;
						a {
							text-indent: -999px;
							background-image: url('../../images/icon-home.png');
							background-repeat: no-repeat;
							background-position: left 10px center;
						}
					}
				}
			}
		}
		.slicknav_btn {
			background: none;
			text-shadow: none;
			margin-top: -35px;
			span {
				span {
					width: 18px;
					height: 2px;
					background-color: $color-main;
					text-shadow: none;
					box-shadow: none;
				}
			}
		}
	}

  .container {
	  position: relative;
  }

  /** Header logo*/
  .logo{ margin: 15px 0; }

  .logo {
	  width: 262px;
	  transition: all 0.4s ease;
	  img {
		  opacity: 1;
		  transition: all 0.2s linear;
		  &:hover {
			  opacity: 0.75;
		  }
	  }
  }

  /** Social medias links */
  .social-medias {
    margin: 0 30px 0 0;
    padding: 0;
    list-style: none;

    li {
      float: left;
      display: inline;

      a {
        display: block;
        color: white;
        font-size: 15px;
        padding: 5px 7px;

        &:hover{
          text-decoration: none;
        }
      }
    }
  }

	 #main-research-container {
		display: none;
		text-align: right;
		position: relative;
		float: right;
		bottom: 10px;
		#main-research {
			width: 200px;
			background: none !important;
			border: 0;
			font-size: 13px;
			color: #686867;
			border-bottom: 1px solid #d6d4d4;
			border-right: 1px solid #d6d4d4;
		}
		#main-research-submit {
			position: absolute;
			top: 3px;
			right: 5px;
			border: 0;
			background: none;
			background-image: url('../../images/glass_black.png');
			width: 13px;
			height: 13px;
		}
	}

  /** Submit a project */
  .submit-project {
    display: inline-block;
    width: auto;
    font-size: 11px;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    background: #575756;
    padding: 10px 15px;

    &:hover {
      background: #444;
      text-decoration: none;
    }
	&.contact {
		background-color: #b7b7b7;
		&:hover {
			background-color: #969696;
		}
	}
  }

  .social-medias, .submit-project{
    float: left;
    display: inline;
  }

  /** Header navigation */
  #header-navigation {

	li {

		&.mobile {
			display: none;
		}
		a{
			padding: 10px 12px;
			position: relative;
			font-size: 17px;
			&::after {
				width: 100%;
			}
			@media (max-width: 1199px) {
				padding: 10px 10px;
			}
		}
		&:hover{
		   	color: #4B4B4B;
        	text-decoration: none;
        	background: none;
	        a{
	        	color: white !important;
		        &::after{
		          opacity: 1;
				  transform: translate3d(-50%, 0px, 0);
		        }
	        }
	    }
	    .dropdown-menu{
	    	li{
	    		margin-left: 0;
	    	}
	    }
	}

    li.active,
    li.current-menu-parent{
      a{
        font-weight: bold;
      }
    }

    a{
      font-size: 18px;
      font-weight: bold;
      color: white;
      text-decoration: none;
      text-transform: uppercase;
      text-align: center;

      .caret {
	        display: none;
        }


      &:hover{
        text-decoration: none;
        background: none;
      }

      &:focus{ background: none !important; }
    }
  }
  #institution {
	  transition: all 0.4s ease;
	  position: absolute;
	  top: 0;
	  right: 15px;
	  opacity: 1;
  }
  .navigation{
    margin-top: 50px;
    transition: all 0.4s ease;
  }

  #header-navigation,
  .search{
    float: right !important;
    display: inline !important;
  }

  .search{
    font-size: 18px;
    color: white;
    margin-top: 7px;
    display: block;

    &:hover{
      text-decoration: none;
    }
  }

  /** Dropdown menu */
  .dropdown-menu{
    top: 40px ;
    border: 0px !important;
    margin: 0 !important;
    padding: 0 !important;
    border-radius: 0px !important;
    background: #575756;
    box-shadow: none !important;
    text-align: left !important;

    li{
      padding: 0px 20px 0px 20px !important;
      text-align: left !important;

      &::after{
        display: none !important;
        content: '';
      }

      &:last-child{ a{ border-bottom: 0px !important; } }
      &:hover{ background: darken(#575756, 3%) !important; }

      a{
        background: none !important;
        font-size: 15px !important;
        color: white !important;
        text-transform: none !important;
        font-weight: normal !important;
        border-bottom: 1px solid lighten(#575756, 10%) !important;
        padding-bottom: 15px;
        padding-top: 15px;
        text-align: left !important;

        &::after{
          display: none !important;
          content: '';
        }
      }
    }
  }

  /** Culture colors */
  .menu-culture{
    .dropdown-menu{
      background: $color-culture !important;

      li a{ border-bottom: 1px solid lighten($color-culture, 10%) !important; }
      li:hover{ background: darken($color-culture, 3%) !important; }
      li:last-child a{ border-bottom: 0px !important;}
    }
  }

  /** Scientifique colors */
  .menu-scientifique{
    .dropdown-menu{
      background: $color-scientifique !important;

      li a{ border-bottom: 1px solid lighten($color-scientifique, 10%) !important; }
      li:hover{ background: darken($color-scientifique, 3%) !important; }
      li:last-child a{ border-bottom: 0px !important; }
    }
  }

  /** Société colors */
  .menu-societe{
    .dropdown-menu{
      background: $color-societe !important;

      li a{ border-bottom: 1px solid lighten($color-societe, 10%) !important; }
      li:hover{ background: darken($color-societe, 3%) !important; }
      li:last-child a{ border-bottom: 0px !important; }
    }
  }

}

.header-ra{
	background-color: white !important;
	box-shadow: 1px 1px 10px #c6c6c6;
	a, a:hover{
		color: #575756 !important;
		font-weight: 100 !important;
		font-size: 16px !important;
	}
	#header-navigation #menu-rapports-annuels li:hover a{
		color: #575756 !important;
	}
	.ddl-pdf, .ddl-pdf:hover{
		font-size: 14px !important;
	}
}

@media (min-width: 768px) {
	#header {
		&.sticky {
			.navigation {
				margin-top: 18px;
			}
			#institution {
				opacity: 0;
				top: -35px;
			}
		}
	}
}
