.page-template-template-contact{
	#footer{
		margin-top: 0;
	}
}

.template-contact{
	margin-top: 215px;
	#contact-content {
		padding: 20px 20px;
	}
	
	h1{
		font-weight: bold;
		font-size: 38px;
		color: $color-interdomaines;
		text-transform: uppercase;
		margin-bottom: 40px;
	}

	h2{
		font-weight: bold;
		font-size: 18px !important;
		color: $color-main;
	}
	
	ul{
		margin: 0;
		padding: 0;
		list-style: none;
		
		&.contacts{
			margin-top: 25px;
		
			li{
				margin-bottom: 10px;
		
				a{
					font-size: 16px;
					color: #616161;
					text-decoration: none;
		
					&.contact-phone, &.contact-email{
						padding: 2px 0 2px 40px;
					}
		
					&.contact-phone{
						background: url(../../images/contact-phone.png) 4px 2px no-repeat;
					}
		
					&.contact-email{
						background: url(../../images/contact-email.png) 2px 2px no-repeat;
					}
		
					&:hover{
						color: darken(#616161, 10%);
						text-decoration: none;
					}
				}
			}
		}
		&.social-medias{
			overflow: hidden;
			margin-top: 40px;
	
			li{
				float: left;
				display: inline;
				margin-right: 15px;
	
				a{
					display: block;
					width: 40px;
					height: 40px;
					-webkit-border-radius: 40px;
					-moz-border-radius: 40px;
					border-radius: 40px;
					background: #D8D9D8;
					text-decoration: none;
					color: white;
					font-size: 20px;
					padding-top: 5px;
					text-align: center;
	
					i {
						position: relative;
						top: 1px;
					}
	
					&:hover{
						background: darken(#D8D9D8, 5%);
						text-decoration: none;
					}
				}
			}
		}
	}
	
	#field_1_1 {
		margin-top: 9px !important;
		.ginput_container_text {
			margin-top: 0 !important;	  
		}
	}

	#field_1_5{
		width: 40% !important;
		float: left !important;
		display: inline !important;
		margin: 0 !important;
		padding: 0 !important;
	}
	
	#field_1_4{
		width: 60% !important;
		float: left !important;
		display: inline !important;
		margin: 0 !important;
		padding: 0 !important;
	}
	
	.gform_wrapper ul li.gfield{
		clear: none !important;
	}
	
	.gform_wrapper{
		ul{
			margin: 0 !important;
			padding: 0 !important;
		}
		
		label{
			display: none !important;
		}
		.gdpr{
			width: 100%;
		    float: left;
		    margin-top: 0;
		    margin-bottom: 15px;
		}
	}
	
	input[type="text"], textarea{
		width: 100% !important;
		border: 1px solid #DEDEDE !important;
		background: #fff !important;
	}
	
	#input_1_4 {
		height: 150px;
	}
	
	.gform_footer {
		text-align: right;
		input[type="submit"] {
			background-color: black !important;
			font-weight: bold;
		    border: none;
		    color: white;
		    font-size: 13px !important;
		    text-transform: uppercase;
		    padding: 14px 33px;
		    float: right;
		    margin: 0 !important;
		    background-color: white;;
			-webkit-transition: background-color 0.1s linear;
	        -moz-transition: background-color 0.1s linear;
	        -o-transition: background-color 0.1s linear;
	        -ms-transition: background-color 0.1s linear;
	        transition: background-color 0.1s linear;
	        &:hover {
		        background-color: darken(white, 3%);
	        }
		}
	}
		
	form {
		.ginput_container_text,
		.ginput_container_email {
			margin-top: 0 !important;
			input {
				padding: 9px 10px !important;
			}
		}
	}

	.map-container{
		margin-top: 130px;
		height: 825px;
		.acf-map{
			height: 100%;
		}
		.marker{
			display: none;
		}
	}
}