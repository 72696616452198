.container-reports, .container-archive-reports, .template-logo{

    #culturel {
        color: #337ab7;
    }
    #age-et-societe {
        color: #efbf00;
    }
    #scientifique {
        color: #e74011;
    }
    .press-cat {
        text-transform: uppercase;
        margin: 80px 0 40px 0;
    }


  h1{
    margin-bottom: 40px;
  }

  .card-report{
    width: 250px;
    padding-bottom: 40px;
    margin-bottom: 40px;
    border-top: 1px solid #D8D8D8;
    border-left: 1px solid #D8D8D8;
    border-right: 1px solid #D8D8D8;
    border-bottom: 6px solid #D8D8D8;
    text-align: center;

    &:hover{
      background: #FAFAFA;
    }

    img{
      margin: auto;
    }

    h2 {
        padding: 0 10px;
    }
    h2, h2 a{
      font-size: 18px;
      color: #464849;
      font-weight: bold;
      margin-bottom: 40px;
      text-decoration: none;

      &:hover{
        color: darken(#464849, 10%);
      }
    }

    a.see{
      font-size: 16px;
      color: #464849;
      font-weight: normal;
      margin-bottom: 15px;
      display: inline-block;
      i {
          font-size: 21px;
          vertical-align: middle;
      }
      &:hover{
         color: darken(#464849, 10%);
         text-decoration: none;
      }
  }

    a.download{
      font-size: 16px;
      color: #464849;
      font-weight: normal;
      background: url(../../images/reports-download.png) no-repeat;
      padding-left: 40px;
      display: inline-block;
      &:hover{
         color: darken(#464849, 10%);
         text-decoration: none;
      }
    }
  }

  .btn-archive{
    margin-top: -10px;
    margin-bottom: 0;
    color: #888;
    border: 1px solid #dadada;
    &:hover, &:focus{
      background-color: transparent;
      color: #555;
    }
  }

  .year-filter{
    float: right;
    margin-top: -70px;
    position: relative;
    .title{
      cursor: pointer;
      border-bottom: 1px solid #dadada !important;
      font-size: 16px;
      color: #888;
      font-weight: 400;
      border-radius: 0;
      min-width: 200px;
      height: 40px;
      padding: 10px;
      display: inline-block;
      background-color: transparent;
      &.cross-close{
        .icon{
          width: 20px;
          height: 20px;
          float: right;
          position: initial;
          margin-right: -10px;
          &:after{
            right: 10px;
          }
        }
      }
      .icon:before, .icon:after{
        content: '';
        background-color: #888;
        height: 18px;
        width: 1px;
        right: 10px;
        top: 10px;
        position: absolute;
        transform: rotate(45deg);
      }
      .icon:after{
        right: 22px;
        transform: rotate(-45deg);
      }
    }
    .years{
      display: none;
      position: absolute;
      top: 40px;
      background-color: white;
      z-index: 1;
      width: 100%;
      p{
        cursor: pointer;
        color: #888;
        border-bottom: 1px solid #dadada !important;
        min-height: 40px;
        padding: 10px;
        margin-bottom: 0;
        font-size: 16px;
        &:hover{
          color: #555;
        }
      }
    }
  }
}
