.single-rapport_annuel {

    .red {
        color: $color-scientifique;
    }
    .yellow {
        color: $color-societe;
    }
    .blue {
        color: $color-culture;
    }

    #header {
        #header-navigation {
            ul {
                li {
                    position: relative;
                    .top-menu {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 15px;
                        width: 100%;
                        opacity: 0;
                        height: auto;
                        font-size: 12px;
                        text-transform: uppercase;
                        transition: all 0.2s ease-in-out;
                        color: black;
                        white-space: nowrap;

                        &.red {
                            color: $color-scientifique !important;
                        }
                        &.yellow {
                            color: $color-societe !important;
                        }
                        &.blue {
                            color: $color-culture !important;
                        }
                    }
                    &.active {
                        &.red {
                            
                            a {
                                color: $color-scientifique !important;
                            }
                        }
                        &.yellow {
                            
                            a {
                                color: $color-societe !important;
                            }
                        }
                        &.blue {
                            
                            a {
                                color: $color-culture !important;
                            }
                        }
                    }
                    &:hover {
                        .top-menu {
                            opacity: 1;
                            top: -10px;
                        }
                    }
                }
            }
        }
    }
}


$base-margin: 110px;
$text-color: #575756;

.modal-backdrop{
    opacity: 0 !important;
}
.header-top-ra{
    transition: all 0.4s ease;
    position: absolute;
    top: 15px !important;
    right: 47px !important;
    opacity: 1;
    z-index: 2;
    a{
        color: #575756;
        &:hover{
            text-decoration: none;
        }
    }
    i{
        margin-left: 5px;
    }
}

#page-rapport-annuel {
    color: $text-color;
    font-size: 16px;
    margin-top: 185px;
    h1, h2, h3, h4, h5, h6 {
        color: $text-color;
    }
    a {
        color: $text-color;
        text-decoration: underline;
    }
    p {
        color: $text-color;
        line-height: 1.7em;
        margin-bottom: 15px;
    }
    img {
        height: auto;
    }

    .back-to-site{
        margin-top: 60px;
        text-align: center;
        a{
            display: inline-block;
            border: 1px solid white;
            text-align: center;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: bold;
            color: white;
            text-decoration: none;
            width: auto;
            padding: 10px 15px;
            border: 1px solid #54585A;
            color: #54585A;
            transition: background-color 0.1s linear;
        }
    }

    .copyright.under-img{
        text-align: left;
        position: absolute;
        bottom: 20px;
        left: 20px;
        font-size: 12px;
        .call-to-action{
            color: #575756;
            text-decoration: underline;
            font-size: 16px;
            font-weight: bold;
            i{
                margin-right: 5px;
            }
            &.link-mode{
                display: block;
            }
        }
    }

    .hover-container{
        display: inline-block;
        overflow: hidden;
        width: 100%;
        .img{
            transition: .25s all;
            height: 315px;
            background-size: cover;
            background-position: center;
        }
        &:hover .img{
            transform: scale(1.03);
        }
    }

    .open-modal{
        cursor: pointer;
    }
    .modal-dialog-centered{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50%;
    }
    .modal-content{
        padding: 40px 30px 30px 30px;
        border-radius: 0;
        color: black;
        box-shadow: 0 2px 4px 5px rgba(230, 230, 230, 0.5);
        max-height: 800px;
        overflow: scroll;
        @media (max-height: 800px) {
            max-height: 500px;
        }
        @media (max-height: 500px) {
            max-height: 350px;
        }
        .modal-header{
            border-bottom: none;
        }
        .close{
            position: absolute;
            top: 30px;
            right:37px;
            height: 27px;
            width: 27px;
            opacity: 1;
            z-index: 10;
            &:before, &:after{
                height: 27px;
                width: 1px;
                background-color: black;
                position: absolute;
                top: -3px;
                left: 16px;
                content: '';
                transform: rotate(45deg);
            }
            &:after{
                transform: rotate(-45deg);
            }
        }
        .modal-body{
            position: initial;
            padding: 0;
            #slider{
                margin-top: 15px;
                .slide{
                    height: 100%;
                    img{
                        max-height: 400px;
                        margin: 0 auto;
                    }
                    .legend{
                        margin-top: 5px;
                        font-size: 12px;
                        text-align: center;
                        color: #575756;
                    }
                }
                .slick-prev:before, .slick-next:before{
                    color: black !important;
                }
            }
        }
    }

    .mailto-link{
        display: block;
        text-align: right;
    }
    .intro-text{
        .mailto-link{
            visibility: visible;
            animation-name: fadeIn;
            position: absolute;
            bottom: 20px;
            text-align: center;
            width: 100%;
            left: 0;
        }
    }
    .hide-bars{
        display: none;
    }

    .bottom-note-title{
        margin-top: 30px;
        margin-bottom: 0;
    }
    .bottom-note{
        margin-bottom: 3px;
        font-size: 12px;
        p{
            display: initial;
        }
        &:last-of-type{
            margin-bottom: 25px;
        }
    }

    #eclairage-content, #interview-content, #dialogue-content{
        sup{
            cursor: pointer;
        }
    }

    .orgue-point{
        background-color: #fbfbfb;
        padding: 50px 0;
        .point-left{
            text-align: left;
            img{
                border-radius: 0 !important;
            }
            .mention{
                margin-top: 10px;
            }
        }
        .point-right{
            .up-title{
                font-size: 22px;
                margin-bottom: 10px;
                color: black;
                font-size: 32px;
                font-weight: bold;
            }
        }
    }

    pre{
        font-size: 12px;
        margin-top: -20px;
        background-color: transparent;
        border: none;
        padding: 0;
        font-family: "Century Gothic", Helvetica, Arial, sans-serif;
        word-wrap: break-word;
        white-space: pre-wrap;
    }

    .intro-menu .menu li:hover a{
        text-decoration: underline !important;
    }

    .bio{
        .builder-element-content{
            padding: 50px 0;
            border-top: 1px solid #575756;
            margin-top: 50px;
        }
        .one-bio{
            margin: 0 0 25px 0 !important;
            h3{
                margin-top: 0;
            }
        }
    }


    .graph{
        .bar-container{
            padding: 15px 0;
            .row{
                display: flex;
                align-items: center;
            }
            .bar{
                height: 80px;
                background-color: black;
                transition: 1s all;
                max-width: 100%;
                color: white;
                font-weight: bold;
                padding: 28px 10px;
                &.bar-total{
                    background-color: transparent;
                    padding: 0;
                    .sub-bar{
                        height: 100%;
                        float: left;
                        padding: 28px 10px;
                        margin-left: 0;
                        transition: 1s all;
                        &:nth-child(1){
                            background-color: #00569D;
                        }
                        &:nth-child(2){
                            background-color: #EFBF00;
                        }
                        &:nth-child(3){
                            background-color: #E74011;
                        }
                        &:nth-child(4){
                            background-color: #575756;
                        }
                    }
                }
            }
            &:nth-child(1) .bar{
                background-color: #00569D;
            }
            &:nth-child(2) .bar{
                background-color: #EFBF00;
            }
            &:nth-child(3) .bar{
                background-color: #E74011;
            }
            &:nth-child(4) .bar{
                background-color: #575756;
            }
        }
        .total{
            font-weight: bold;
            position: absolute;
            right: 0;
            &:after{
                content: "";
                position: absolute;
                right: 15px;
                height: 10px;
                bottom: 19px;
                width: 2px;
                background-color: #575756;
            }
        }
    }
    .graph-top{
        .stats{
            min-height: 470px;
            &:nth-child(2) .title{
                background-color: #00569D !important;
            }
            &:nth-child(3) .title{
                background-color: #EFBF00 !important;
            }
            &:nth-child(4) .title{
                background-color: #E74011 !important;
            }
            &:nth-child(5) .title{
                background-color: #575756 !important;
            }
        }
        .graph-arrow{
            font-size: 30px;
            cursor: pointer;
            margin-right: 10px;
            &.right{
                margin-left: 10px;
                margin-right: 0px;
            }

        }
    }

    .read-more-curtain {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 200px;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+50&0+0,1+50 */
        background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 75%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 75%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 75%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
        .read-more {
            padding: 19px 53px;
            color: white;
            text-transform: uppercase;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            background-color: $color-interdomaines;
            text-decoration: none;
            &:hover {
                text-decoration: none;
            }
        }
    }

    .section {
        margin-top: 75px;
        overflow: hidden;
        position: relative;
        &.read-full {

            max-height: inherit !important;
            overflow: inherit !important;
            .read-more-curtain {
                display: none !important;
            }
        }
        &:last-child {
            padding-bottom: $base-margin;
        }
        &#carteblanche{
            .banner-title:before{
                background: url('../../images/border-large-white.png') top left no-repeat !important;
            }
            .banner-title:after{
                background: url('../../images/border-large-white.png') bottom right no-repeat !important;
            }
        }
        .section-banner {
            text-align: center;
            position: relative;
            max-width: 1800px;
            margin: 0 auto;
            .banner-title {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                color: white;
                padding: 30px 65px;
                font-size: 40px;
                font-weight: bold;
                max-width: 85%;
                display: table;
                background-color: rgba(0,0,0,0.35);
                &.chiffres {
                    font-size: 50px;
                    padding: 42px 134px;
                }
                &:before {
                    width: 10px;
                    height: 10px;
                    content: '';
                    display: block;
                    background: blue;
                    top: -10px;
                    left: -10px;
                    position: absolute;
                    width: 128px;
                    height: 58px;
                    background: url('../../images/border-large-black.png') top left no-repeat;
        	    }

        	    &:after{
                    content: '';
                    display: block;
                    bottom: -10px;
                    right: -10px;
                    position: absolute;
                    width: 128px;
                    height: 58px;
                    background: url('../../images/border-large-black.png') bottom right no-repeat;
        	    }
            }
            img{
                max-width: 1390px;
            }
            .image-copyright {
                text-align: left;
                font-size: 12px;
                margin: 10px auto 0 auto;
                max-width: 1390px;
                padding: 0 10px;
            }
        }

        .section-intro {
            margin-top: 80px;
            .intro-left {
                .intro-bloc {
                    padding: 45px 25px;
                    background-color: #F6F6F6;
                    font-size: 14px;
                    .title {
                        font-size: 38px;
                        font-weight: bold;
                        margin: 0 0 10px 0;
                    }
                    .copyright{
                        font-size: 12px;
                    }
                    .text {
                        margin-top: 30px;
                        font-size: 14px;
                        p {
                            font-size: 14px;
                        }
                    }
                    .share {
                        margin-top: 20px;
                        font-size: 13px;
                        font-weight: bold;
                        .social-share {
                            margin-top: 17px;
                            li {
                                margin-right: 13px;
                                a {
                                    display: inline-block;
                                    color: white;
                                    border-radius: 100%;
                                    font-size: 16px;
                                    .fa {
                                        color: white;
                                    }
                                }
                                &.facebook {
                                    a {
                                        background-color: #3b5a99;
                                        padding: 4px 10px;
                                    }
                                }
                                &.twitter {
                                    a {
                                        background-color: #5b8ec8;
                                        padding: 4px 8px;
                                    }
                                }
                                &.linkedin {
                                    a {
                                        background-color: #127bb7;
                                        padding: 4px 8px;
                                    }
                                }
                                &.google {
                                    a {
                                        background-color: #d44a36;
                                        padding: 4px 5px;
                                    }
                                }
                                &.email {
                                    a {
                                        background-color: #575756;
                                        padding: 4px 7px;
                                    }
                                }
                                &:last-child {
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
                .intro-menu {
                    margin-bottom: 10px;
                    .menu {
                        list-style: none;
                        margin: 0;
                        padding: 0;
                        .menu-title{
                            font-weight: bold;
                            margin-left: 20px;
                            margin-top: 0;
                            font-size: 18px;
                        }
                        li {
                            margin: 0;
                            padding: 0;
                            border-bottom: 1px solid #E1E1E1;
                            a {
                                font-size: 14px;
                                padding: 10px 20px;
                                display: block;
                                text-decoration: none;
                            }
                            &:last-child {
                                border-bottom: 0;
                            }
                        }
                    }
                }
            }
            .intro-right {
                max-width: 850px;
                float: right;
                .title {
                    margin: 0 0 65px 0;
                    font-size: 40px;
                    font-weight: normal;
                }
                .sub-title {
                    font-size: 21px;
                    font-weight: bold;
                    margin: 0 0 65px 0;
                }
            }
        }

        .section-builder {
            .builder-content {
                .builder-element {
                    margin: 70px 0 0 auto;
                    &:first-of-type{
                        margin-top: 0;
                    }
                    &.citation {
                        max-width: 950px;
                        .citation-text {
                            font-size: 30px;
                            p {
                                display: inline;
                            }
                            &:before {
                                content: '«';
                                margin-right: 10px;
                            }
                            &:after {
                                content: '»';
                                margin-left: 0;
                            }
                        }
                        .citation-mention {
                            text-align: right;
                            font-style: italic;
                            margin-top: 5px;
                        }
                    }
                    &.texte {
                        max-width: 820px;
                        .text-text {
                            h1,h2,h3,h4,h5,h6 {
                                margin-bottom: 10px;
                                &:first-child {
                                    margin-bottom: 10px;
                                }
                            }
                            p {
                                margin-bottom: 35px;
                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                    &.video {
                        max-width: 820px;
                        .video {
                            position: relative;
                            width: 100%;
                            height: 0;
                            padding-bottom: 52%;
                            margin-left: auto;
                            margin-right: auto;
                            background-color: black;
                            iframe {
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                left: 0;
                                top: 0;
                            }
                        }
                        .video-mention {
                            margin-top: 10px;
                        }
                    }
                    &.image-fit {
                        max-width: 820px;
                        .image-fit-image-copyright {
                            margin-top: 10px;
                        }
                    }
                    &.image-23-texte-13,
                    &.texte-13-image-23 {
                        max-width: 1050px;
                        .image-23-texte-13-image-copyright,
                        .texte-13-image-23-image-copyright {
                            margin-top: 10px;
                        }
                    }
                    &.image-12-texte-12,
                    &.texte-12-image-12 {
                        max-width: 1050px;
                        .image-12-texte-12-image-copyright,
                        .texte-12-image-12-image-copyright {
                            margin-top: 10px;
                        }
                    }
                    &.bloc {
                        max-width: 950px;
                        padding: 90px 130px;
                        .bloc-title {
                            font-size: 40px;
                            font-weight: bold;
                            margin: 0;
                        }
                        .bloc-sous-titre {
                            font-size: 18px;
                            font-weight: bold;
                            margin-top: 35px;
                        }
                        .bloc-list {
                            margin: 0;
                            padding: 0;
                            list-style: none;
                            li {
                                margin-top: 35px;
                                font-size: 18px;
                                .num {
                                    font-size: 40px;
                                    font-weight: bold;
                                }
                                p {
                                    display: inline;
                                }
                            }
                        }
                    }
                    &.video {
                        .video-title {
                            font-size: 20px;
                            font-weight: bold;
                            margin-bottom: 40px;
                        }
                    }
                }
            }
        }

        &.culture {
            .section-banner {
                .banner-title {
                    &:before {
                        background: url('../../images/border-large-blue.png') top left no-repeat;
            	    }

            	    &:after{
                        background: url('../../images/border-large-blue.png') bottom right no-repeat;
            	    }
                }
            }
            .intro-left {
                .intro-bloc {
                    .title {
                        color: black;
                    }
                }
            }
            .intro-right {
                .title {
                    color: $color-culture;
                }
            }
            .section-builder {
                .builder-content {
                    .builder-element {
                        &.citation {
                            .citation-text {
                                color: $color-culture;
                                p {
                                    color: $color-culture;
                                }
                            }
                        }
                        &.bloc {
                            background-color: rgba($color-culture, 0.1);
                        }
                    }
                }
            }
            #interview-point {
                .point-right {
                    .title {
                        color: $color-culture;
                    }
                }
            }
        }

        &.age {
            .section-banner {
                .banner-title {
                    &:before {
                        background: url('../../images/border-large-yellow.png') top left no-repeat;
            	    }

            	    &:after{
                        background: url('../../images/border-large-yellow.png') bottom right no-repeat;
            	    }
                }
            }
            .intro-left {
                .intro-bloc {
                    .title {
                        color: black;
                    }
                }
            }
            .intro-right {
                .title {
                    color: $color-societe;
                }
            }
            .section-builder {
                .builder-content {
                    .builder-element {
                        &.citation {
                            .citation-text {
                                color: $color-societe;
                                p {
                                    color: $color-societe;
                                }
                            }
                        }
                        &.bloc {
                            background-color: rgba($color-societe, 0.1);
                        }
                    }
                }
            }
        }

        &.sciences-sante,
        &.scientifique {
            .section-banner {
                .banner-title {
                    &:before {
                        background: url('../../images/border-large-red.png') top left no-repeat;
            	    }

            	    &:after{
                        background: url('../../images/border-large-red.png') bottom right no-repeat;
            	    }
                }
            }
            .intro-left {
                .intro-bloc {
                    .title {
                        color: black;
                    }
                }
            }
            .intro-right {
                .title {
                    color: $color-scientifique;
                }
            }
            .section-builder {
                .builder-content {
                    .builder-element {
                        &.citation {
                            .citation-text {
                                color: $color-scientifique;
                                p {
                                    color: $color-scientifique;
                                }
                            }
                        }
                        &.bloc {
                            background-color: rgba($color-scientifique, 0.1);
                        }
                    }
                }
            }
        }

    }

    #regards {
        padding-top: 0;

        #regards-intro {
            .container {
                display: flex;
            }
            .intro-image {
                width: 649px;
                height: auto;
                max-width: 100%;
                float: left;
                position: relative;
                img {
                    width: 100%;
                }
            }
            .intro-text {
                float: left;
                width: 491px;
                max-width: 100%;
                height: auto;
                padding: 40px;
                background-color: #F4F4F4;
                text-align: right;
                overflow: hidden;
                position: relative;
                padding-bottom: 80px !important;
                .title {
                    font-size: 60px;
                    font-weight: bold;
                    margin: 0;
                }
                .sub-title {
                    font-size: 30px;
                    font-weight: bold;
                    margin: 0;
                }
                .intro {
                    font-size: 30px;
                    font-weight: bold;
                    margin: 0;
                    color: #9C9C9D;
                }
                .text {
                    font-size: 24px;
                    margin: 0;
                    margin-top: 60px;
                    p {
                        line-height: 1.4em;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
                .copyright {
                    text-align: left;
                    position: absolute;
                    bottom: 20px;
                    left: 10px;
                    font-size: 12px;
                }
            }
        }

        #regards-edito {
            margin-top: 60px;
            .title {
                text-align: center;
                max-width: 950px;
                margin: 0 auto;
                font-size: 40px;
                font-weight: bold;
            }
            .sub-title {
                text-align: center;
                margin: 0;
                font-size: 16px;
                font-weight: normal;
                text-transform: uppercase;
                margin-top: 30px;
            }
            .intro {
                font-size: 21px;
                font-weight: bold;
                margin-top: $base-margin;
            }
            .text-container {
                padding-top: 0;
                position: relative;
                max-height: 200px;
                overflow: hidden;
                &.text-container--bigger {
                    max-height: 400px;
                }
                &.read-full {
                    max-height: inherit !important;
                    overflow: inherit !important;
                    .read-more-curtain {
                        display: none !important;
                    }
                }
            }
            .signatures {
                width: 100%;
                max-width: 305px;
                margin: 70px 0 0 auto;
                display: block;
                .signature {
                    float: left;
                    margin-right: 40px;
                    &:last-child {
                        margin-right: 0;
                    }
                    .nom {
                        font-weight: bold;
                    }
                    img {
                        margin-top: 30px;
                    }
                }
                &.inline{
                    max-width: none;
                    float: right;
                    width: auto;
                    .signature{
                        max-width: 265px;
                    }
                }
            }
        }

        .external-link{
            margin-top: -20px;
            margin-bottom: 20px;
            a{
                font-size: 14px;
            }
        }

        #regards-sommaire {
            margin-top: $base-margin;
            .sommaire-intro {
                font-size: 24px;
                margin: 0 0 45px 0;
            }
            .sommaire-element {
                display: block;
                text-decoration: none;
                .title {
                    font-size: 24px;
                    font-weight: bold;
                    margin: 0;
                    margin-top: 26px;
                    color: black;
                    &:after {
                        display: block;
                        content: '';
                        width: 100px;
                        height: 3px;
                        margin-top: 10px;
                        background-color: black;
                    }
                }
                .text {
                    margin-top: 25px;
                    font-size: 18px;
                    color: black;
                    p {
                        color: black;
                        min-height: 90px;
                    }
                    .small {
                        font-size: 14px;
                        line-height: 1.5em;
                        display: block;
                    }
                }
                &:hover {
                    text-decoration: none;
                }
                &.culture {
                    .title {
                        color: black;
                        &:after {
                            background-color: $color-culture;
                        }
                    }
                }
                &.age {
                    .title {
                        color: black;
                        &:after {
                            background-color: $color-societe;
                        }
                    }
                }
                &.scientifique {
                    .title {
                        color: black;
                        &:after {
                            background-color: $color-scientifique;
                        }
                    }
                }
            }
        }
    }


    #interview {

        #interview-point {
            margin-top: $base-margin;
            margin-bottom: $base-margin;
            .point-left {
                img {
                    border-radius: 100%;
                }
                .mention {
                    font-size: 18px;
                    margin-top: 25px;
                    text-align: center;
                }
            }
            .point-right {
                padding-left: 40px;
                .title {
                    font-size: 40px;
                    font-style: italic;
                    margin: 0;
                }
                .sub-title {
                    font-size: 21px;
                    font-weight: normal;
                    margin-top: 25px;
                }
                .text {
                    margin-top: 25px;
                }
            }
        }

    }


   .intro-personnes {
        margin-bottom: 100px;
        .personne {
            img {
                margin-bottom: 35px;
                max-width: 287px;
                width: 100%;
                cursor: pointer;
            }
            .personne-nom {
                text-align: center;
                font-weight: bold;
                max-width: 287px;
            }
            .personne-description {
                text-align: center;
                max-width: 287px;
            }
        }
    }

    #chiffres {

        #chiffres-graph {
            margin-top: $base-margin;
            .graph-top {
                .graph-top-left {
                    .title {
                        font-weight: bold;
                        font-size: 20px;
                        color: white;
                        text-transform: uppercase;
                        background-color: black;
                        padding: 9px 18px;
                        display: inline-block;
                    }
                    .chiffre {
                        margin-top: 55px;
                        .nombre {
                            font-size: 38px;
                            color: black;
                        }
                        .description {
                            font-size: 23px;
                        }
                    }
                }
                .graph-top-right {
                    #chiffres-graph-chart {
                        max-width: 100% !important;
                    }
                }
            }
            .graph-bottom {
                margin-top: $base-margin;
                .nav {
                    .onglet-trigger {
                        text-transform: uppercase;
                        padding: 19px 61px;
                        display: block;
                        float: left;
                        text-decoration: none;
                        &.culture {
                            color: $color-culture;
                        }
                        &.age {
                            color: $color-societe;
                        }
                        &.sciences-sante {
                            color: $color-scientifique;
                        }
                        &.inter {
                            color: $color-interdomaines;
                        }
                        &.active {
                            &.culture {
                                color: white;
                                background-color: $color-culture;
                            }
                            &.age {
                                color: white;
                                background-color: $color-societe;
                            }
                            &.sciences-sante {
                                color: white;
                                background-color: $color-scientifique;
                            }
                            &.inter {
                                color: white;
                                background-color: $color-interdomaines;
                            }
                        }
                        &:hover {
                            &.inactive {
                                background-color: #f9f9f9;
                            }
                            text-decoration: none;
                        }
                    }
                }
                .content-graph-onglets {
                    padding: 100px 80px;
                    display: none;
                    &.culture {
                        background-color: rgba($color-culture, 0.1);
                    }
                    &.age {
                        background-color: rgba($color-societe, 0.1);
                    }
                    &.sciences-sante {
                        background-color: rgba($color-scientifique, 0.1);
                    }
                    &.inter {
                        background-color: rgba($color-interdomaines, 0.1);
                    }
                    &.active {
                        display: block;
                    }
                    .chiffre {
                        text-align: center;
                        .nombre {
                            font-size: 43px;
                            color: black;
                        }
                        .description {
                            font-size: 21px;
                        }
                    }
                }
            }
        }

    }

}

#page-rapport-annuel .carte-blanche-slider{
    .slide{
        height: 350px;
        img{
            max-height: 400px;
            margin: 0 auto;
        }
        .legend{
            margin-top: 5px;
            font-size: 12px;
            text-align: center;
            color: #575756;
        }
    }
    .slick-prev:before, .slick-next:before{
        color: black !important;
    }
}

@media (max-width: 1200px) {
    .graph-top-right{
        display: none
    }
    .graph-top-left{
        flex: 100%;
        max-width: 100%;
        width: 100%;
        text-align: center;
    }
    .graph-arrow{
        position: absolute;
        top: calc(50% - 15px);
        left: 15px;
        &.right{
            right: 15px;
            left: auto !important;
        }
    }
    .stats{
        min-height: 0 !important;
    }
    .intro-text{
        width: 559px !important;
    }
}

@media (max-width: 1199px) {

    #page-rapport-annuel {
        #regards {
            #regards-intro {
                .container {
                    display: block;
                }
            }
        }
    }

}

@media (max-width: 767px) {

    .banner-title.chiffres{
        font-size: 46px !important;
    }

    iframe{
        width: 100%;
    }

    #menu-rapports-annuels {
        display: none !important;
    }

    .single-rapport_annuel {
        #header .logo-container {
            margin-top: 0 !important;
            text-align: left !important;
        }
        #header .logo {
            margin: 15px 0 0 15px;
        }
        #header .navigation {
            margin-top: 0 !important;
        }
        #header #mobile-navigation .slicknav_btn {
            margin-top: -50px !important;
        }
        #mobile-navigation{
            z-index: 2;
            .top-menu{
                display: none;
            }
        }
        .header-top-ra{
            font-size: 12px !important;
        }
    }

    .modal-dialog-centered{
        width: 90% !important;
    }

    #page-rapport-annuel #regards #regards-intro .intro-text {
        height: auto;
    }
    #page-rapport-annuel #regards #regards-intro .intro-text .copyright {
        top: 10px;
        bottom: inherit;
    }
    #page-rapport-annuel #regards #regards-intro .intro-text .title {
        font-size: 45px;
        text-align: center;
    }
    #page-rapport-annuel #regards #regards-intro .intro-text .sub-title {
        font-size: 22px;
        text-align: center;
    }
    #page-rapport-annuel #regards #regards-intro .intro-text .intro {
        font-size: 23px;
    }
    #page-rapport-annuel #regards #regards-intro .intro-text .text {
        font-size: 20px;
        margin-top: 30px;

        h2 {
            font-size: inherit;
        }
    }
    #page-rapport-annuel #regards #regards-sommaire .sommaire-intro {
        font-size: 20px;
    }
    #page-rapport-annuel p {
        line-height: 1.6em;
    }

    #page-rapport-annuel #regards #regards-edito .title {
        font-size: 34px;
    }
    #page-rapport-annuel #regards #regards-edito .intro {
        font-size: 18px;
    }
    #page-rapport-annuel #regards #regards-edito .signatures {
        max-width: 100%;
    }

    #page-rapport-annuel #regards #regards-sommaire .sommaire-element {
        margin-bottom: 60px;
        &:last-child {
            margin-bottom: 0;
        }
    }

    #page-rapport-annuel .section .section-intro .intro-right {
        float: none;
        margin-top: 40px;
    }
    #page-rapport-annuel .section .section-intro .intro-right .title {
        font-size: 35px;
    }
    #page-rapport-annuel .section .section-intro .intro-right .sub-title {
        font-size: 18px;
    }

    #page-rapport-annuel .section .section-builder .builder-content .builder-element.citation .citation-text {
        font-size: 27px;
    }
    #page-rapport-annuel .section .section-builder .builder-content .builder-element.bloc {
        padding: 30px 30px;
    }
    #page-rapport-annuel .section .section-builder .builder-content .builder-element.bloc .bloc-title {
        font-size: 30px;
    }
    #page-rapport-annuel .section .section-builder .builder-content .builder-element.bloc .bloc-list li {
        font-size: 16px;
    }
    #page-rapport-annuel .section .section-builder .builder-content .builder-element.bloc .bloc-list li .num {
        font-size: 30px;
    }

    #page-rapport-annuel #interview #interview-point .point-right {
        padding-left: 15px;
        margin-top: 50px;
    }

    #page-rapport-annuel .intro-personnes .personne {
        margin-bottom: 50px;
    }

    #page-rapport-annuel .section .section-banner .banner-title {
        font-size: 28px !important;
        padding: 15px 40px !important;
        width: calc(100% - 60px) !important;
        max-width: 100% !important;
    }

    #page-rapport-annuel #chiffres #chiffres-graph .graph-bottom .nav .onglet-trigger {
        float: none;
        text-align: center;
    }
    #page-rapport-annuel #chiffres #chiffres-graph .graph-bottom .content-graph-onglets {
        padding: 40px 40px;
    }
    #page-rapport-annuel #chiffres #chiffres-graph .graph-bottom .content-graph-onglets .chiffre {
        margin-bottom: 40px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    #page-rapport-annuel #chiffres #chiffres-graph .graph-bottom .content-graph-onglets .chiffre .nombre {
        font-size: 30px;
    }
    #page-rapport-annuel #chiffres #chiffres-graph .graph-bottom .content-graph-onglets .chiffre .description {
        font-size: 18px;
    }
    #page-rapport-annuel #chiffres #chiffres-graph .graph-top .graph-top-left .chiffre {
        margin-top: 50px;
    }
    #page-rapport-annuel #chiffres #chiffres-graph .graph-top .graph-top-left .chiffre .nombre {
        font-size: 30px;
    }
    #page-rapport-annuel #chiffres #chiffres-graph .graph-top .graph-top-left .chiffre .description {
        font-size: 18px;
    }
    #chiffres-graph-chart {
        margin-top: 100px;
    }

    #page-rapport-annuel .read-more-curtain .read-more {
        padding: 15px 30px;
    }
    .postid-10072 {
        #page-rapport-annuel .copyright.under-img {
            position: static;
            background-color: #e7e6e6;
            padding: 15px;
        }
    }
    #page-rapport-annuel {
        margin-top: 0;
    }

}
