
  .criterion_menus{
    margin: 10px 0 40px 0;
    padding: 0;
    list-style: none;
    max-width: 200px;

    .sub-menu {
        padding-left: 30px;
        list-style: disc;
        li {
            a {
                padding: 10px 0 0 0;
                font-size: 14px;
            }
        }
    }

    li{
      border-bottom: 1px solid #dadada;
      &.has-sub-menu {
          border-bottom: 0 !important;
      }
      &:last-child {
	      border-bottom: 0;
      }
      &.active {
	      a {
		      font-weight: bold;
	      }
      }
      &.current-menu-item {
          a {
              font-weight: bold;
          }
      }

      &.culturel {
          &.active {
              a {
                  color: #337ab7;
              }
          }
      }
      &.ages-societe {
          &.active {
              a {
                  color: #efbf00;
              }
          }
      }
      &.sciences-sante {
          &.active {
              a {
                  color: #e74011;
              }
          }
      }
      &.fondation {
          &.active {
              a {
                  color: #333;
              }
          }
      }
      &.sante-personnalisee-societe-santeperso {
          &.active {
              a {
                  color: #333;
              }
          }
      }

      a{
	    display: block;
	  	padding: 15px 0;
        font-size: 16px;
        font-weight: normal;
        color: #888;
        text-decoration: none;

        &:hover{
          color: #555;
          background-color: inherit;
        }
        &:focus{
          color: #555;
          background-color: inherit;
        }

      }
    }
    &.culture {
	    li {
		    &.active {
			    a {
				    color: $color-culture;
			    }
		    }
	    }
    }
    &.ages-societe {
	    li {
		    &.active {
			    a {
				    color: $color-societe;
			    }
		    }
	    }
    }
    &.sciences-sante {
	    li {
		    &.active {
			    a {
				    color: $color-scientifique;
			    }
		    }
	    }
    }
  }
  .container-criterion {
	  position: relative;

      h1 {
          margin-bottom: 0;
      }

    .criterion-element {
        h2 {
            padding-top: 50px !important;
            margin-top: 0;
        }
    }
  }
