.content{
  line-height: 24px;

  h2{
    font-size: 28px;
    color: #575756;
  }
  p{
    font-size: 15px;
    color: #575756;
    margin-bottom: 30px;
  }
}

.types_horizontal_listing {
  .type {
    a {
      font-weight: normal;
      transition: all .4s linear;
    }
    &.active {
      a {
        font-weight: bold;
      
      }
    }
  }
}
