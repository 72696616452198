/** Footer **/
#footer{
  width: 100%;
  background: #54585A;
  padding: 80px 0px 20px 0;
  font-size: 16px;
  color: white;
  margin-top: 60px;
  
  &.no-margin {
	  margin-top: 0;
  }
  
  .footer-bottom {
	  font-size: 16px;
  }
  
  .menu-footer-contact-container, .project-submit {
	  &:before {
		  content: "";
		  display: block;
		  width: 1px;
		  height: 70px;
		  background-color: #979797;
		  position: absolute;
		  left: -30px;
	  }
  }

  .access{
    padding-top: 30px;

    a{
      font-size: 14px;
      font-weight: normal;
      color: white;
      background: url(../../images/footer-access.png) no-repeat;
      padding-left: 25px;
    }
  }

  .contact-infos{
    a{
      color: white;
    }
    .mail{
      margin-top: 10px;
    }
  }

  .project-submit{
    a{
      display: inline-block;
      border: 1px solid white;
      text-align: center;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: bold;
      color: white;
      text-decoration: none;
      width: auto;
      padding: 10px 25px;
      background-color: #54585A;
      -webkit-transition: background-color 0.1s linear;
      -moz-transition: background-color 0.1s linear;
      -o-transition: background-color 0.1s linear;
      -ms-transition: background-color 0.1s linear;
      transition: background-color 0.1s linear;
      &:hover {
        background-color: lighten(#54585A, 4%);
      }
    }
  }


  .footer-socials{
    margin: 0;
    padding: 0;
    list-style: none;

    li{
      float: left;
      display: inline;
      margin-left: 15px;

      a{
        display: block;
        width: 40px;
        height: 40px;
        background: rgba(255, 255, 255, 0.4);
        -webkit-border-radius: 40px;
        -moz-border-radius: 40px;
        border-radius: 40px;
        color: white;
        font-size: 18px;
        padding: 2px 0px 0px 0px;
        text-decoration: none;
        text-align: center;
        
        i {
			position: relative;
			top: 6px;
        }

        &:hover{
          text-decoration: none;
          background: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }

  h5{
    font-size: 18px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0 0 10px 0;
    padding: 0;
  }

  ul{
    margin: 0;
    padding: 0;
    list-style: none;

    li{
	    line-height: 1.3;
      margin-bottom: 3px;
      &:last-child {
	      margin-bottom: 0;
      }

      a{
        color: white;
        font-size: 16px;
        font-weight: normal;
      }
    }
  }

  .footer-newsletter{
    border-left: 1px solid #979797;
    padding-left: 40px;
    .gform_wrapper {
	    margin: 0;
	    .gform_footer {
		    margin: 0;
	    }
	    .gfield {
		    margin: 0;
		    padding: 0;
		    .gfield_label {
			    display: none;
		    }
		    .ginput_container {
			    margin: 0;
		    }
	    }
    }

    input[type="text"]{
      height: 55px;
      width: 100%;
      background: #383838;
      border: 0px;
      color: white;
      padding: 0 20px;
      font-size: 14px;

      &:hover{
        background: darken(#383838, 3%);
      }

      &:focus{
        background: darken(#383838, 6%);
      }
    }

    input[type="submit"]{
      font-size: 18px;
      color: white;
      text-align: right;
      width: 100%;
      background: url(../../images/footer-form-submit.png) right no-repeat !important;
      border: 0px !important;
      padding-right: 40px;
      &:hover {
	      text-decoration: underline;
      }
    }
  }

  .footer-upper{
    margin-bottom: 40px;
  }

  .footer-credentials{
    margin-top: 40px;
    a{
      color: white;
    }

    .copyright{
      font-size: 13px;
      font-weight: normal;
      margin-top: 30px;
    }
  }
	
}