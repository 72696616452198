#field_1_1 {
    margin-top: 9px !important;
    .ginput_container_text {
        margin-top: 0 !important;
    }
}

#field_1_5{
    width: 40% !important;
    float: left !important;
    display: inline !important;
    margin: 0 !important;
    padding: 0 !important;
}

#field_1_4{
    width: 60% !important;
    float: left !important;
    display: inline !important;
    margin: 0 !important;
    padding: 0 !important;
}

.gform_wrapper ul li.gfield{
    clear: none !important;
}

.gform_wrapper{
    ul{
        margin: 0 !important;
        padding: 0 !important;
    }

    label{
        display: none !important;
    }
}

.ginput_complex {
    margin-bottom: -15px;
    input {
        margin-bottom: 15px;
    }
}

#input_1_4 {
    height: 150px;
}

.gform_footer {
    text-align: right;
    input[type="submit"] {
        background: none;
        border: 1px solid #DEDEDE;
        color: #464849;
        font-size: 13px !important;
        padding: 14px 33px;
        float: right;
        margin: 0 !important;
        background-color: white;;
        -webkit-transition: background-color 0.1s linear;
        -moz-transition: background-color 0.1s linear;
        -o-transition: background-color 0.1s linear;
        -ms-transition: background-color 0.1s linear;
        transition: background-color 0.1s linear;
        &:hover {
            background-color: darken(white, 3%);
        }
    }
}

form {
    .ginput_container_text,
    .ginput_container_email {
        margin-top: 0 !important;
        input {
            padding: 9px 10px !important;
        }
    }

    input[type="text"], textarea{
        width: 100% !important;
        border: 1px solid #DEDEDE !important;
        background: #fff !important;
    }
    .gfield_checkbox {
        li {
            input {
                margin-top: 0px!important;
            }
            label {
                display: inline-block!important;
            }
        }
    }
}
