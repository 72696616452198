#timeline {
  #years {
    position: absolute;
    left: 0px;
    &.normal {
      position: absolute;
      bottom: auto;
      top: auto;
    }
    &.fix {
      position: fixed;
      top: 115px;
      bottom: auto;
    }
    &.bot {
      position: absolute;
      top: auto;
      bottom: 685px;
    }
    ul {
      li {
        list-style-type: none;
        margin-top: 10px;
        float: none;
        a {
          border-bottom: 1px solid #a9a9a9;
          font-size: 20px;
          color: #a9a9a9;
          padding: 0px 15px 5px 15px;
          border-radius: 0px;
          &:hover {
            text-decoration: none;
          }
        }
        &.active {
          a {
            background: none;
            color: #54585b;
            border-bottom: 1px solid #54585b;
          }
        }
        &:last-child {
          a {
            border-bottom: none;
          }
        }
      }
    }
  }

  #line {
    position: absolute;
    width: 5px;
    left: 50%;
    right: 50%;
    background: #a9a9a9;
    height: 500px;
    &:before,
    &:after {
      content: "";
      width: 30px;
      height: 30px;
      border-radius: 100%;
      background: #54585b;
      display: block;
      position: absolute;
      left: -13px;
    }
    &:before {
      top: 0px;
    }
    &:after {
      bottom: 0px;
    }
  }
  #line-fill {
    position: absolute;
    width: 5px;
    left: 50%;
    background: #54585b;
    transition: height .3s ease-in-out;
  }
  #timeline-container {
    .elements-timeline {
      width: 100%;
      .element-position {
        max-width: 45%;
        clear: both;
        padding-top: 50px;
        p {
          margin-bottom: 0px;
        }
        .bar {
          height: 5px;
          width: 40px;
          background: #54585b;
          bottom: 30px;
          position: relative;
          z-index: -1;
        }

        .year {
          font-weight: bold;
          height: 60px;
          width: 60px;
          color: white;
          background: #54585b;
          border-radius: 100%;
          text-align: center;
          line-height: 56px;
          position: absolute;
          right: calc(50% - 32px);
          margin-top: 150px;
        }
        .timeline-content-container{
            display: flex;
            .picture{
                flex-basis: 40%;
            }
            .content{
                flex-basis: 60%;
                padding-left: 25px;
            }
        }
        &.right {
          .bar {
            float: right;
            left: 21px;
          }
        }
        &.left {
          .bar {
            float: left;
            right: 21px;
          }
        }
      }
      &:last-child .element-position{
        padding-bottom: 120px;
        .year{
            margin-top: 80px;
        }
      }
    }
  }
}
    #today {
      margin-top: 50px;
      text-align: center;
      a {
        font-size: 18px;
        color: #54585b;
      }
    }
