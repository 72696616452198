html,
body {
  -webkit-font-smoothing: antialiased;
  font-family: $font-century;
  overflow-x: hidden !important;
}

body {
  position: relative;
}

h4 {
  line-height: 1.4em;
}

a {
  cursor: pointer;

  &:focus {
    text-decoration: none;
    outline: none;
  }
}

/* WordPress */
.wp-caption,
.gallery-caption {
  color: #666;
  font-size: 13px;
  font-size: 0.8125rem;
  font-style: italic;
  margin-bottom: 1.5em;
  max-width: 100%;
}

.wp-caption img[class*="wp-image-"] {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption .wp-caption-text {
  margin: 0.8075em 0;
  font-size: 14px;
}

.bypostauthor>.comment-body>.comment-meta>.comment-author .avatar {
  border: 1px solid #333;
  padding: 2px;
}

.alignleft {
  display: inline;
  float: left;
  margin-right: 1.5em;
}

.alignright {
  display: inline;
  float: right;
  margin-left: 1.5em;
}

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

img.alignleft {
  float: left;
  margin-right: 1.5em;
}

img.alignright {
  float: right;
  margin-left: 1.5em;
}

/* End WordPress */

.section-videos .slick-dots {
  width: 100% !important;
  right: initial !important;
  bottom: 15px !important;
  top: initial !important;
  left: 0;
  transform: translate(0, -50%) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-videos .slick-dots li {
  margin: 0 15px !important;
}

.search-highlight {
  color: white;
  background-color: $color-main;
}

.searchform-search-page {
  margin-top: 75px;
  margin-bottom: 50px;
  display: inline-block;
  width: 100%;

  form {
    margin: auto;

    label {
      display: none !important;
    }

    input {
      width: 340px;
      height: 65px;
      display: inline-block;
      float: left;
      background: white;
      padding: 15px 20px;
      border: 0px !important;
      border: 1px solid black !important;
      text-transform: uppercase;
      color: #000;

      &:focus {
        outline: 0 !important;
      }
    }

    input[type="submit"] {
      background: black;
      color: white;
      text-transform: uppercase;
      width: 175px;
      font-weight: 300;
    }
  }
}

.custom-bourse-search-results {
  .name {
    .job {
      color: white;
      font-weight: 600;
    }

    .boursier-term-search {
      color: white;
      font-weight: 600;
    }
  }
}

#back-to-top {
  display: none;
  position: fixed;
  bottom: 0;
  right: 50px;
  z-index: 100;
  opacity: 0.7;

  a {
    display: block;
    background-color: #b7b7b7;
    color: white;
    padding: 3px 16px;
    font-size: 13px;
  }
}

img {
  max-width: 100%;
}

.slick-track {
  margin: 0 auto;
}

.affix {
  position: fixed !important;
  width: 262px;
  top: 115px;
}

.validation_error {
  font-size: 12px !important;
  color: white !important;
  background: #D84A4A !important;
  font-weight: bold !important;
  border: 0px !important;
  margin-bottom: 7px !important;
}

.gfield_error {
  background: none !important;
  border: 0px !important;
  color: #D84A4A !important;
}

.gform_wrapper .validation_message {
  color: #D84A4A !important;
}

.gform_wrapper .field_description_below .gfield_description {
  padding-top: 3px !important;
}

.gform_wrapper li.gfield.gfield_error.gfield_contains_required div.gfield_description {
  position: absolute !important;
  width: auto !important;
}

.gform_wrapper li.gfield.gfield_error.gfield_contains_required {
  padding-right: 16px !important;
}

.thumb-head {
  //margin-bottom: 30px;
}

.list-info {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    color: #464849;
    font-size: 18px;
    margin-bottom: 8px;

    a {
      color: #464849;
      font-size: 18px;

      &:hover {
        text-decoration: underline;
      }
    }

    i {
      display: inline-block !important;
      background-repeat: no-repeat;
      background-position: center center;
      position: relative;
      width: 26px !important;
      height: 26px !important;
      margin-right: 10px;
      float: left;

      &.category {
        background-image: url('../../images/logo-category.png');
      }

      &.fa-clock-o {
        top: 2px;
        left: 2px;
        font-size: 24px;
      }

      &.website {
        background-image: url('../../images/logo-website.png');
        padding-left: 0 !important;
      }

      &.location {
        background-image: url('../../images/logo-location.png');
      }
    }
  }
}

#breadcrumb {
  .breadcrumb {
    padding: 0;
    background: none;
    border-radius: 0;
    font-size: 12px;
    color: #555759;
    text-transform: uppercase;

    a {
      font-size: 12px;
      color: #555759;
    }
  }
}

// Bloc separator
.separator {
  border-bottom: 5px solid #EDEDED;
  margin: 40px 0 40px 0;
}

.btn-primary {
  text-transform: uppercase;
  background: none;
  border: 0;
  border-radius: 0;
  color: white;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  padding: 10px 15px;
  margin: 15px 0 30px;
  -webkit-transition: background-color 0.1s linear;
  -moz-transition: background-color 0.1s linear;
  -o-transition: background-color 0.1s linear;
  -ms-transition: background-color 0.1s linear;
  transition: background-color 0.1s linear;

  &:hover {
    color: white;
  }

  &:focus {
    outline: none;
    box-shadow: 0;
  }

  &.culture {
    background-color: $color-culture;

    &:hover {
      background-color: lighten($color-culture, 5%);
    }
  }

  &.sciences-sante {
    background-color: $color-scientifique;

    &:hover {
      background-color: lighten($color-scientifique, 5%);
    }
  }

  &.societe {
    background-color: $color-societe;

    &:hover {
      background-color: lighten($color-societe, 5%);
    }
  }

  &.interdomaines {
    background-color: $color-interdomaines;

    &:hover {
      background-color: lighten($color-interdomaines, 5%);
    }
  }
}

textarea:focus,
input:focus,
*:focus {
  outline: none;
}

.project-sidebar {
  ul {
    li {
      color: #464849;
      font-size: 18px;

      a {
        color: #464849;
        font-size: 18px;
      }

      &.category {
        i {
          display: inline-block;
          width: 26px;
          height: 26px;
          background-image: url('../../images/logo-category.png');
          background-repeat: no-repeat;
          background-position: center center;
          margin-right: 10px;
          position: relative;
          top: 8px;
        }
      }

      &.website {
        i {
          display: inline-block;
          width: 26px;
          height: 26px;
          background-image: url('../../images/logo-website.png');
          background-repeat: no-repeat;
          background-position: center center;
          margin-right: 10px;
          position: relative;
          top: 8px;
        }
      }
    }
  }
}

.article-sidebar {
  ul {
    li {
      color: #464849;
      font-size: 18px;

      a {
        color: #464849;
        font-size: 18px;
      }
    }
  }
}

.sidebar-bloc {
  background: #F8F8F8;
  padding: 40px;

  h4 {
    font-size: 16px;
    font-weight: normal;
    color: #555759;
    margin: 0 0 20px 0;
    padding: 0;

    &.infos-title {
      font-size: 21px;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 30px;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    &.sidebar-categories {
      li {
        margin-bottom: 10px;

        &.culture {
          a {
            border-color: $color-culture;
          }
        }

        &.ages-societe {
          a {
            border-color: $color-societe;
          }
        }

        &.age-et-societe {
          a {
            border-color: $color-societe;
          }
        }

        &.sciences-sante {
          a {
            border-color: $color-scientifique;
          }
        }

        a {
          display: block;
          font-size: 16px;
          color: #383838;
          text-transform: uppercase;
          font-weight: bold;
          border-left: 10px solid #383838;
          padding: 10px 0px 10px 15px;

          &:hover {
            color: #444;
            text-decoration: underline;
          }
        }
      }
    }
  }

  form {
    margin-bottom: 40px;
    overflow: hidden;

    label {
      display: none !important;
    }

    ul,
    li {
      margin: 0 !important;
      padding: 0 !important;
      list-style: none !important;
    }

    .ginput_container {
      margin: 0px !important;
    }

    .gform_body,
    .gform_footer {
      clear: none !important;
      float: left !important;
      display: inline !important;
      margin: 0 !important;
      padding: 0 !important;
    }

    .gform_body {
      width: 80% !important;
    }

    .gform_footer {
      width: 20% !important;
    }

    input[type="email"],
    input[type="text"],
    input[type="submit"] {
      display: block !important;
      width: 100% !important;
      height: 45px !important;
      background: #DEDEDE !important;
      border: 0px !important;
      margin: 0 !important;
      padding: 0 !important;
    }

    input[type="text"] {
      padding-left: 15px !important;
      padding-right: 15px !important;
      font-size: 15px !important;
    }

    input[type="submit"] {
      background: #383838 !important;
      color: #fff !important;
      font-weight: bold !important;

      &:hover {
        background: #444 !important;
      }
    }
  }

}

#pagination {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      margin-right: 12px;

      a {
        font-size: 12px;
        color: #54585A;
        padding: 10px 18px;
        border: 1px solid #54585A;
        border-radius: 3px;
        display: block;

        &:hover {
          text-decoration: none;
        }
      }

      &.active,
      &:hover {
        a {
          background-color: #54585A;
          color: white;
        }
      }
    }
  }
}

.page-numbers {
  border: 1px solid #54585A;
  display: inline-block;
  width: auto;
  padding: 10px 15px;
  color: #54585A;
  text-decoration: none;
  color: #54585A;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;

  &:hover {
    background: #54585A;
    color: white;
    text-decoration: none;
  }

  &.current {
    background: #54585A;
    color: white;
  }

}

.title-bottom {
  font-size: 28px;
  color: #555759;
  padding-bottom: 10px;
  margin-bottom: 35px;
}

.section-title {
  color: #555759;
  font-weight: normal;
  font-size: 28px;
  margin-bottom: 25px;

  &:after {
    content: "";
    display: block;
    width: 25px;
    height: 3px;
    margin-top: 10px;
  }

  &.culture {
    &:after {
      background-color: $color-culture;
    }
  }

  &.interdomaines {
    &:after {
      background-color: $color-interdomaines;
    }
  }

  &.age {
    &:after {
      background-color: $color-societe;
    }
  }

  &.sciences-sante {
    &:after {
      background-color: $color-scientifique;
    }
  }
}

.title-line {
  position: relative;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;

  span {
    position: relative;
    display: inline-block;
    width: auto;
    z-index: 9999;
    background: white;
    padding: 10px 55px;
    font-size: 18px;
  }

  &:before {
    position: absolute;
    top: 50%;
    left: 0;
    content: '';
    width: 100%;
    height: 2px;
    background: #383838;
    z-index: 8888;
  }

  &.culture {
    &:before {
      background: $color-culture;
    }
  }

  &.ages-societe {
    &:before {
      background: $color-societe;
    }
  }

  &.sciences-sante {
    &:before {
      background: $color-scientifique;
    }
  }

}

.social-share {
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
  margin-top: 40px;
  overflow: hidden;

  li {
    float: left;
    display: inline;
    margin-right: 40px;

    a {
      color: #888;
      font-size: 20px;
      text-decoration: none;

      &:hover {
        color: #444;
        text-decoration: none;
      }
    }
  }
}

/**
* Video modal for section pages
*/
.section-video-modal {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  text-align: center;
  z-index: 9999;
  padding-top: 500px;
  cursor: pointer;
}

.bourse {
  position: relative;

  .date {
    font-size: 18px;
    color: #464849;
    margin-bottom: 15px;
    margin-top: 5px;
  }
}

.slicknav_menu {
  display: none;
}

.homepage {

  /** Slides **/
  #slider {
    .slick-arrow {
      z-index: 10;
      width: 14px;
      height: 23px;

      &.slick-next {
        right: 20px !important;
        left: inherit !important;
        background-image: url('../../images/homepage_slider_next.png');

        &:before {
          content: "";
        }
      }

      &.slick-prev {
        left: 20px !important;
        right: inherit !important;
        background-image: url('../../images/homepage_slider_prev.png');

        &:before {
          content: "";
        }
      }
    }

    .slick-dots {
      bottom: 15px;

      li {
        display: inline-block;
        ;
        margin: 0;
        width: 16px;
        height: 16px;
        margin: 0 8px;

        &.slick-active {
          button {
            &:before {
              background: url('../../images/dot_full.png') no-repeat;
              opacity: 1;
            }
          }
        }

        button {
          padding: 0;
          width: 16px;
          height: 16px;
          font-size: inherit;
          margin: 0;

          &:before {
            width: 16px;
            height: 16px;
            content: "";
            background: url('../../images/dot.png') no-repeat;
            background-size: 100%;
            padding: 0;
            opacity: 1;
          }
        }
      }
    }
  }

  .slide {
    height: 550px;
    background-position: center;
    width: 100%;
    margin: auto;
    position: relative;

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    &.video {
      iframe {
        width: 100%;
        height: 590px;
      }
    }

    .banner {
      width: 100%;
      padding: 30px 0;
      position: absolute;
      bottom: 80px;
      color: white;
      z-index: 2;
      display: block;

      .informations,
      .details {
        float: left;
        display: inline;
      }

      .informations {
        width: 39%;
        border-right: 1px solid white;
        padding-right: 20px;

        a {
          color: white;
        }
      }

      .slider-content {
        display: inline-block;
        width: 100%;
      }

      .details-container {
        display: inline-block;
        width: 60%;
        position: absolute;
        height: 100%;
        padding-left: 35px;
      }

      .details {
        width: 100%;
        position: relative;
        top: 50%;
        transform: translateY(-50%);

        ul {
          margin: 0;
          padding: 0;
          list-style: none;

          li {
            margin-bottom: 10px;
            padding-left: 25px;
          }
        }

        .date {
          background: url(../../images/homepage-slider-date.png) no-repeat;
        }

        .location {
          background: url(../../images/homepage-slider-location.png) no-repeat;
        }

        span {
          display: block;
          width: 52px;
          height: 52px;
          background: url(../../images/homepage-slider-link.png) no-repeat;
          position: absolute;
          top: 0;
          right: 0;

          &:hover {
            opacity: 0.7;
          }
        }
      }

      h2 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 26px;
        line-height: 31px;
        color: white;
        margin: 0;
        padding: 0;
        margin-bottom: 2px;
      }

      .description {
        font-size: 14px;
        color: white;
      }

      &.culture {
        background: rgba(0, 86, 157, 0.9);
      }

      &.age {
        background: rgba(255, 204, 0, 0.9);
      }

      &.sciences-sante {
        background: rgba(231, 64, 17, 0.9);
      }

      &.default {
        background: rgba(0, 0, 0, 0.5);
      }
    }
  }

  /** Presentation **/
  .homepage-presentation {
    margin-top: 60px;
    margin-bottom: 60px;

    .presentation-title {
      font-size: 12px;
      color: #555759;
      text-transform: uppercase;
      padding-bottom: 10px;
    }

    .presentation-slogan {
      font-size: 38px;
      font-weight: bold;
      color: #464849;
      text-transform: uppercase;
      line-height: 1.2;
      padding-right: 0;
    }

    .presentation-content {
      font-size: 16px;
      color: #575756;
      line-height: 1.8;
      padding-left: 50px;
    }

    .presentation-link {
      width: 30px;
      height: 32px;
      background: url(../../images/standard-link.png) no-repeat;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  /** Blocs **/
  .homepage-blocs {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    margin-bottom: 60px;

    .homepage-bloc {
      width: 33.3333%;
      overflow: hidden;
      float: left;
      display: inline;
      position: relative;

      &:hover {
        text-decoration: none;

        a {
          text-decoration: none;
        }

        .video {
          opacity: 1 !important;
        }
      }

      .image-container {
        height: 235px;
        width: 100%;
        overflow: hidden;
        position: relative;

        .background {
          background-size: cover;
          background-position: center;
          transform: scale(1);
          width: 100%;
          height: 100%;
          transition: all 0.6s ease-in-out;
        }

        .video {
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          transition: all 0.4s;
        }
      }

      .bloc-details {
        padding: 15px 60px 15px 60px;
        min-height: 220px;

        .homepage-bloc-details-content {
          position: relative;
          top: 50%;
        }

        &.culture {
          background-color: #00569D;
        }

        &.age {
          background-color: #FFCC00;
        }

        &.sciences-sante {
          background-color: #E74011;
        }

        h3 {
          font-size: 26px;
          font-weight: bold;
          color: white;
          text-transform: uppercase;
          display: inline-block;
          width: auto;
          padding-bottom: 10px;

        }

        .description {
          font-size: 18px;
          color: white;
          font-weight: 500;
        }


      }

      .project-container {
        padding: 0 60px 0 60px;
        margin-top: -60px;

        &:last-of-type {
          margin-bottom: 50px;
        }

        .project {
          display: block;

          &.culture {
            border: 1px solid #00569D;
          }

          &.age {
            border: 1px solid #FFCC00;
          }

          &.sciences-sante {
            border: 1px solid #E74011;
          }

          .project-background,
          .section-video {
            background-size: cover;
            background-position: center;
            height: 160px;
          }

          .name {
            min-height: 125px;
            padding: 15px 20px;
            font-weight: bold;
            font-size: 22px;
            color: #464849;
          }
        }

        &.video {
          margin-top: 40px;

          .slick-track,
          .section-video {
            width: 100% !important;
          }
        }

        .link {
          margin-top: 10px;
          text-align: right;
          display: block;
          color: #464849;
        }
      }

    }
  }

  .homepage-projets {
    margin-top: 100px;
    margin-bottom: 80px;
    position: relative;


    h2 {
      font-size: 28px;
      color: #555759;
      padding-bottom: 10px;
    }

    h3 {
      font-size: 34px;
      color: #464849;
      font-weight: bold;

      a {
        color: #464849;
        text-decoration: none;

        &:hover {
          color: darken(#464849, 10%);
        }
      }
    }

    font-size: 15px;
    color: #575756;
    line-height: 1.8;

  }

  #homepage-actions-container {
    background-color: #F5F5F5;

    .section-videos {
      .slick-slide {}
    }

  }

  .homepage-actions {
    width: 100%;
    display: inline-block;

    .homepage-action-left,
    .homepage-action-right {

      .description {
        font-size: 14px;
        color: #FFFFFF;
        height: 135px !important;
      }

      .link {

        a {
          display: block;
          border: 1px solid white;
          font-size: 16px;
          font-weight: bold;
          text-transform: uppercase;
          color: white;
          padding: 25px 14px;
          margin-top: 30px;
          text-align: center;

          &:hover {
            text-decoration: none;
          }
        }

      }
    }

    .homepage-action-left {
      background: #2D2D2D;

      .description {
        padding: 28px 40px 0px 0;
      }

      #bottom-vid-part-left {
        width: 585px;
        margin: 0 auto 0 0;
        padding-left: 15px;
        padding-right: 15px;
      }

      .link {

        a {
          &:hover {
            background-color: darken(#2D2D2D, 5%);
          }
        }

      }
    }

    .homepage-action-right {
      background: #767676;

      .description {
        padding: 28px 40px 40px 15px;
      }

      #bottom-vid-part-right {
        width: 585px;
        margin: 0 0 0 auto;
        padding-right: 15px;
        padding-left: 15px;
      }

      .link {
        padding-right: 0;

        a {
          &:hover {
            background-color: darken(#767676, 5%);
          }
        }

      }

    }
  }


  .homepage-news {
    margin-top: 0;
    padding-bottom: 60px;

    .row-articles {
      display: inline-block;
    }

    a.all-news {
      display: block;
      height: 40px;
      background: url(../../images/news-all-icon.png) no-repeat right;
      padding-right: 60px;
      padding-top: 21px;
      font-size: 18px;
      color: #575756;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    h2 {
      margin-top: 60px;
      font-size: 28px;
      color: #555759;
      padding-bottom: 10px;
      margin-bottom: 20px;
      word-wrap: break-word;
    }

    h3 {
      font-weight: bold;
      font-size: 34px;
      color: #464849;
      word-wrap: break-word;
      margin-top: 0;

      a {
        color: #464849;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .content {
      font-size: 15px;
      color: #575756;
      line-height: 1.8;
    }

    .date {
      font-size: 14px;
      color: #7F8081;
      margin-bottom: 20px;
    }
  }
}



.homepage-news-background {
  width: 100%;
  height: 360px;
  background-size: cover;
  position: relative;
}

.homepage-news-large {
  width: 70%;
  display: block;
  background: white;
  position: relative;
  margin: auto;
  margin-top: -80px;
  padding: 30px;
  margin-bottom: 100px;
}

.homepage-news-bloc {
  display: block;
  width: 80%;
  background: white;
  padding: 40px;
  margin: 0 auto;
  position: relative;
  margin-bottom: 40px;
}

.over-top {
  margin-top: -120px;
}

.homepage-news-bloc-short {
  display: block;
  margin: 25px 0 0 0;
}

.home-news-large {
  .header-infos {
    color: white;
    padding: 20px;
    display: inline-block;
    position: absolute;
    top: 0;
    z-index: 10;

    .info {
      display: inline-block;
      font-size: 16px;
      line-height: 25px;

      &:first-child {
        margin-right: 30px;
      }

      &:last-child {
        margin-right: 0;
      }

      i {
        display: inline-block !important;
        background-repeat: no-repeat;
        background-position: center center;
        position: relative;
        width: 26px !important;
        height: 26px !important;
        margin-right: 10px;
        float: left;

        &.clock {
          background-image: url('../../images/clock-white.png');
        }

        &.location {
          background-image: url('../../images/location-white.png');
        }
      }
    }

    &.sciences-sante {
      background-color: $color-scientifique;
    }

    &.culture {
      background-color: $color-culture;
    }

    &.ages-societe {
      background-color: $color-societe;
    }

    &.age-et-societe {
      background-color: $color-societe;
    }

    &.interdomaines {
      background-color: #383838;
    }

    &:hover {
      color: white;
      text-decoration: none;
    }
  }

  .category {
    margin: 0 auto;
    position: absolute;
    top: 0;
    right: 60px;

    .cat {
      display: inline-block;
      color: #fff;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 16px;
      color: white;
      padding: 7px 14px;
      -webkit-transition: background-color 0.1s linear;
      -moz-transition: background-color 0.1s linear;
      -o-transition: background-color 0.1s linear;
      -ms-transition: background-color 0.1s linear;
      transition: background-color 0.1s linear;

      &.sciences-sante {
        background-color: $color-scientifique;

        &:hover {
          background-color: lighten($color-scientifique, 5%);
        }
      }

      &.culture {
        background-color: $color-culture;

        &:hover {
          background-color: lighten($color-culture, 5%);
        }
      }

      &.ages-societe {
        background-color: $color-societe;

        &:hover {
          background-color: lighten($color-societe, 5%);
        }
      }

      &.age-et-societe {
        background-color: $color-societe;

        &:hover {
          background-color: lighten($color-societe, 5%);
        }
      }

      &.interdomaines {
        background-color: #383838;

        &:hover {
          background-color: lighten(#383838, 5%);
        }
      }

      &:hover {
        color: white;
        text-decoration: none;
      }
    }
  }
}

.home-news-short {
  margin-bottom: 40px;

  .cat {
    position: relative;
  }

  .category {
    a {
      display: block;
      padding: 7px 14px;
      color: white;

      &:hover {
        text-decoration: none;
      }

      &:focus {
        text-decoration: none;
      }
    }

    display: inline-block;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
    position: absolute;
    top: 0;
    right: 30px;
    color: white;
    display: inline-block;
    -webkit-transition: background-color 0.1s linear;
    -moz-transition: background-color 0.1s linear;
    -o-transition: background-color 0.1s linear;
    -ms-transition: background-color 0.1s linear;
    transition: background-color 0.1s linear;
    background-color: #383838;
    &:hover {
      background-color: lighten(#383838, 5%);
    }

    &.sciences-sante {
      background-color: $color-scientifique;

      &:hover {
        background-color: lighten($color-scientifique, 5%);
      }
    }

    &.culture {
      background-color: $color-culture;

      &:hover {
        background-color: lighten($color-culture, 5%);
      }
    }

    &.ages-societe {
      background-color: $color-societe;

      &:hover {
        background-color: lighten($color-societe, 5%);
      }
    }

    &.age-et-societe {
      background-color: $color-societe;

      &:hover {
        background-color: lighten($color-societe, 5%);
      }
    }

    &.interdomaines {
      background-color: #383838;

      &:hover {
        background-color: lighten(#383838, 5%);
      }
    }

    &:hover {
      color: white;
      text-decoration: none;
    }
  }
}

.homepage-news-more {
  margin-top: 20px;
}

.homepage-news-small,
.homepage-news-large {

  .homepage-news-large-category,
  .homepage-news-small-category {
    display: inline-block;
    width: auto;
    background: $color-main;
    position: absolute;
    height: 40px;
    top: -40px;
    padding: 10px 20px;
    font-weight: bold;
    color: white;

    &:hover {
      text-decoration: none;
    }

    /** colors by categories */
    &.ages-societe {
      background: $color-societe;

      &:hover {
        background: darken($color-societe, 5%);
      }
    }

    &.culture {
      background: $color-culture;

      &:hover {
        background: darken($color-culture, 5%);
      }
    }

    &.sciences-sante {
      background: $color-scientifique;

      &:hover {
        background: darken($color-scientifique, 5%);
      }
    }
  }
}

h1.title-multi {
  font-size: 28px;
  color: #555759;
  padding-bottom: 10px;
}

.years_horizontal_listing {
  margin: 40px 0 0 0;
  padding: 0;
  list-style: none;
  max-width: 827px;
  max-width: 724px;
  display: inline-block;
  float: left;

  li {
    float: left;
    display: inline;

    &.active {
      a {
        color: darken(#A3A3A3, 10%);
        text-decoration: none;
        background: #F5F5F5;
      }
    }

    a {
      display: block;
      font-size: 24px;
      color: #A3A3A3;
    }

    &.year {
      a {
        padding: 10px 25px;

        &:hover {
          color: darken(#A3A3A3, 10%);
          text-decoration: none;
          background: #F5F5F5;
        }
      }
    }

    &.type {
      a {
        padding: 10px 25px;

        &:hover {
          color: darken(#A3A3A3, 10%);
          text-decoration: none;
          background: #F5F5F5;
        }
      }
    }

    &.more {
      margin: 10px 0 0 25px;
    }
  }
}

/**
* Documents navigation (sidebar)
*/

.sections-selector {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: none;
  position: fixed;
  top: 0;
  z-index: 100;

  .content {
    position: fixed;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
  }

  h2 {
    display: block;
    margin: auto;
    max-width: 400px !important;
    font-size: 32px !important;
    font-weight: normal !important;
    color: white !important;
    text-align: center !important;
    margin-bottom: 30px !important;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    max-width: 400px;
    margin: auto;

    li {

      a {
        max-width: 400px;
        display: block;
        padding: 20px 40px;
        background: red;
        text-align: center;
        color: white;
        font-size: 20px;

        &.culture {
          background: $color-culture;

          &:hover {
            background: darken($color-culture, 1%);
          }
        }

        &.ages-societe {
          background: $color-societe;

          &:hover {
            background: darken($color-societe, 1%);
          }
        }

        &.sciences-sante {
          background: $color-scientifique;

          &:hover {
            background: darken($color-scientifique, 1%);
          }
        }

        &.interdomaines {
          background: $color-interdomaines;

          &:hover {
            background: lighten($color-interdomaines, 1%);
          }
        }

        &:hover {
          text-decoration: none;
        }

      }
    }
  }

  a.close-selector {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
    max-width: 400px;
    margin: auto;
    text-align: center;
    font-weight: bold;
    color: white;
    font-size: 15px;
    margin-top: 40px;

    &:hover {
      text-decoration: none;
    }
  }
}

.sections-project-selector {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: none;
  position: fixed;
  top: 0;
  z-index: 100;

  .content {
    position: fixed;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
  }

  h2 {
    display: block;
    margin: auto;
    max-width: 400px !important;
    font-size: 32px !important;
    font-weight: normal !important;
    color: white !important;
    text-align: center !important;
    margin-bottom: 30px !important;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    max-width: 400px;
    margin: auto;

    li {

      a {
        max-width: 400px;
        display: block;
        padding: 20px 40px;
        background: red;
        text-align: center;
        color: white;
        font-size: 20px;

        &.culture {
          background: $color-culture;

          &:hover {
            background: darken($color-culture, 1%);
          }
        }

        &.ages-societe {
          background: $color-societe;

          &:hover {
            background: darken($color-societe, 1%);
          }
        }

        &.sciences-sante {
          background: $color-scientifique;

          &:hover {
            background: darken($color-scientifique, 1%);
          }
        }

        &.interdomaines {
          background: $color-interdomaines;

          &:hover {
            background: lighten($color-interdomaines, 1%);
          }
        }

        &:hover {
          text-decoration: none;
        }

      }
    }
  }

  a.close-project-selector {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
    max-width: 400px;
    margin: auto;
    text-align: center;
    font-weight: bold;
    color: white;
    font-size: 15px;
    margin-top: 40px;

    &:hover {
      text-decoration: none;
    }
  }
}

#projects-archive {

  .filters {
    margin-top: 40px;
    margin-bottom: 60px;

    .filters-title {
      color: #8D8D8D;
      text-transform: uppercase;
      font-size: 16px;
    }

    .filters-categories {
      margin-top: 15px;

      a {
        color: #383838;
        ;
        text-transform: uppercase;
        text-decoration: none;
        font-weight: bold;
        font-size: 16px;
        padding: 3px 15px;
        transition: .4s all;
        margin: 0 20px;
        position: relative;

        &:after {
          content: '';
          width: 1px;
          height: 20px;
          background-color: #8D8D8D;
          position: absolute;
          right: -20px;
          top: 4px;
        }

        &:first-child {
          margin-left: 0;
          padding-left: 0;
        }

        &:last-child:after {
          display: none;
        }

        &:hover,
        &.active {
          color: white;
          background-color: #383838;

          &:first-child {
            padding-left: 15px;
          }
        }
      }
    }
  }

  .projects-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 80px 60px;

    .project-bloc {
      margin-bottom: 0;
    }
  }

  #pagination {
    margin-top: 100px;
  }

  .category {
    display: inline-block;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
    position: absolute;
    top: 0;
    right: 20px;

    .cat {
      color: white;
      display: inline-block;
      padding: 7px 14px;
      -webkit-transition: background-color 0.1s linear;
      -moz-transition: background-color 0.1s linear;
      -o-transition: background-color 0.1s linear;
      -ms-transition: background-color 0.1s linear;
      transition: background-color 0.1s linear;

      &.sciences-sante {
        background-color: $color-scientifique;

        &:hover {
          background-color: lighten($color-scientifique, 5%);
        }
      }

      &.culture {
        background-color: $color-culture;

        &:hover {
          background-color: lighten($color-culture, 5%);
        }
      }

      &.ages-societe {
        background-color: $color-societe;

        &:hover {
          background-color: lighten($color-societe, 5%);
        }
      }

      &.age-et-societe {
        background-color: $color-societe;

        &:hover {
          background-color: lighten($color-societe, 5%);
        }
      }

      &.interdomaines {
        background-color: #383838;

        &:hover {
          background-color: lighten(#383838, 5%);
        }
      }

      &:hover {
        color: white;
        text-decoration: none;
      }
    }
  }
}

.prize-bloc {
  .bloc {
    height: 340px;
    margin-bottom: 20px;

    .bloc-img {
      position: relative;
      height: 200px;
      overflow: hidden;
      background-color: black;
      max-width: 555px;

      img {
        height: auto;
        display: block;
        margin: 0 auto;
        transform: scale(1.0);
        transition: all 0.6s ease-in-out;

        &:hover {
          transform: scale(1.03);
        }
      }

      .category {
        display: inline-block;
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 16px;
        position: absolute;
        bottom: 0;
        left: 35px;

        .cat {
          color: white;
          display: inline-block;
          padding: 7px 14px;
          -webkit-transition: background-color 0.1s linear;
          -moz-transition: background-color 0.1s linear;
          -o-transition: background-color 0.1s linear;
          -ms-transition: background-color 0.1s linear;
          transition: background-color 0.1s linear;

          &.sciences-sante {
            background-color: $color-scientifique;

            &:hover {
              background-color: lighten($color-scientifique, 5%);
            }
          }

          &.culture {
            background-color: $color-culture;

            &:hover {
              background-color: lighten($color-culture, 5%);
            }
          }

          &.ages-societe {
            background-color: $color-societe;

            &:hover {
              background-color: lighten($color-societe, 5%);
            }
          }

          &:hover {
            color: white;
            text-decoration: none;
          }
        }
      }
    }

    .bloc-intro {
      .intro-title {
        margin-top: 20px;

        a {
          color: #464849;
          font-weight: 600;
          font-size: 20px;

          &:hover {}
        }
      }

      p {
        margin: 0;
        color: #575756;
        font-size: 16px;
      }
    }
  }
}

.bourse-bloc {
  height: 249px;
  padding: 0;
  max-width: 166px;
  margin: 0 15px 20px;
  overflow: hidden;
  display: block;
  position: relative;

  &.faded {
    opacity: 0.4;
  }

  img {
    width: 100%;
  }

  &:hover {
    .bourse-hover-container {
      opacity: 1;
      height: 100%;

      .bourse-hover-content {
        transform: translateY(-50%);
        top: 50%;
        bottom: auto;
      }

      h4 {

        &:after {
          height: 4px !important;
        }
      }
    }

    .image-container {
      transform: scale(1.03);
    }
  }

  .image-container {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transform: scale(1.0);
    transition: all 0.6s ease-in-out;
  }

  .bourse-hover-container {
    position: absolute;
    opacity: 1;
    bottom: 0 !important;
    top: auto !important;
    width: 100%;
    max-width: 166px;
    height: 25%;
    /* background-color: rgba(0, 0, 0, 0.5); */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 90%, rgba(0, 0, 0, 1) 100%);
    text-align: center;
    transition: all 0.2s linear;

    .bourse-hover-content {
      width: 100%;
      position: absolute;
      left: 0;
      top: auto;
      bottom: 10px;
      padding: 15px 15px 0 15px;
      transition: all .2s linear;

      .title {
        font-size: 14px;
        color: #fff;
        font-weight: bold;

        &:after {
          content: "";
          display: block;
          width: 58px;
          height: 2px;
          margin: 5px auto 0 auto;
          transition: all .2s linear;
        }

        &.sciences-sante {
          &:after {
            background-color: $color-scientifique;
          }
        }

        &.ages-societe {
          &:after {
            background-color: $color-societe;
          }
        }

        &.culture {
          &:after {
            background-color: $color-culture;
          }
        }
      }

      .name {
        margin-top: 0px;
        color: #fff;
        font-size: 14px;
      }
    }
  }
}

#projects-container {
  margin-bottom: 60px;
  margin-top: 60px;

  .slick-dotted.slick-slider {
    margin-bottom: 0;
    margin-top: 0;
  }

  p {
    margin: 0;
  }

  .section-title {
    margin-top: 0;
  }
}

#projects,
.homepage-news {
  position: relative;

  .slider-navigation {
    text-align: center;
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);

    .slick-arrow {
      width: 7px;
      height: 13px;
      position: relative;
      top: 7px;
      display: inline-block;
      cursor: pointer;
      -webkit-transition: opacity 0.2s linear;
      -moz-transition: opacity 0.2s linear;
      -ms-transition: opacity 0.2s linear;
      -o-transition: opacity 0.2s linear;
      transition: opacity 0.2s linear;

      &.slick-hidden {
        display: none;
      }

      &:hover {
        opacity: 0.6;
      }
    }

    .prev {
      background-image: url('../../images/slider_project_arrow_prev.png');
      margin-right: 15px;
    }

    .dots {
      display: inline-block;

      .slick-dots {
        position: inherit;

        li {
          width: auto;
          height: auto;
          margin: 0 15px 0 0;
          -webkit-transition: opacity 0.2s linear;
          -moz-transition: opacity 0.2s linear;
          -ms-transition: opacity 0.2s linear;
          -o-transition: opacity 0.2s linear;
          transition: opacity 0.2s linear;

          button {
            margin: 0;
            padding: 0;
            background-image: url('../../images/slider_project_dot.png');
            width: 10px;
            height: 10px;

            &:before {
              content: "";
            }
          }

          &:hover {
            opacity: 0.8;
          }

          &.slick-active {
            button {
              width: 17px;
              height: 17px;
              background-image: url('../../images/slider_project_dot_current.png');
            }
          }
        }
      }
    }

    .next {
      background-image: url('../../images/slider_project_arrow_next.png');
    }
  }

  .button {
    /*display: inline-block;
        padding: 9px 14px;
        color: #fff;
        margin-top: 60px;
        &.culture {
            background-color: $color-culture;
        }
        &.age {
            background-color: $color-societe;
        }
        &.interdomaine {
            background-color: $color-interdomaines;
        }
        &.sciences-sante {
            background-color: $color-scientifique;
        }*/
    display: block;
    float: right;
    margin-top: 60px;
    height: 40px;
    background: url(../../images/news-all-icon.png) right no-repeat;
    padding-right: 60px;
    padding-top: 8px;
    font-size: 18px;
    color: #575756;
    text-decoration: none;
  }
}

.projects-btn {
  display: block;
  float: right;
  height: 40px;
  background: url(../../images/news-all-icon.png) right no-repeat;
  padding-right: 60px;
  padding-top: 8px;
  font-size: 18px;
  color: #575756;
  text-decoration: none;
  margin-top: -80px;
}

.all-news {
  margin-top: -80px;
}

#laureat #laureat-content #laureat-prizes .prizes-group-title {
  margin-top: 72px !important;
}

.bourse-search {
  float: right;
}

.loading-spinner {
  width: 36px;
  height: 36px;
  background: url(../../images/default.gif) no-repeat;
}

.homepage-laureats {
  margin-bottom: 0;
  background: #F5F5F5;
  padding: 40px 0 60px 0;
  margin-top: -5px;

  h2 {
    font-size: 28px;
    color: #555759;
    padding-bottom: 10px;
    margin-bottom: 40px;
  }
}

#laureats-table {
  display: inline-block;
  width: 100%;
}

#laureat-table-footer {
  margin: 30px 0 0 0;
  color: #555759;
  font-size: 14px;
  text-align: right;

  a {
    color: #555759;
    font-size: 14px;
    text-transform: uppercase;
    padding: 5px 10px;
    border-right: 1px solid #DADADA;

    &:last-child {
      border-right: 0;
    }

    &.culture {
      color: $color-culture;
    }

    &.ages-societe {
      color: $color-societe;
    }

    &.sciences-sante {
      color: $color-scientifique;
    }
  }
}

.homepage-laureat-double-container,
.homepage-laureat-single-container {
  width: 25%;
  overflow: hidden;
  float: left;
  display: inline;

  .homepage-laureat-single-element {
    width: 100%;
    height: 360px;
    display: block;
    position: relative;
    overflow: hidden;

    &:hover {
      .element-hover-container {
        opacity: 1;
      }

      .image-container {
        transform: scale(1.03);
      }
    }
  }

  .homepage-laureat-double-element {
    width: 100%;
    height: 180px;
    display: block;
    position: relative;
    overflow: hidden;

    &:hover {
      .element-hover-container {
        opacity: 1;
      }

      .image-container {
        transform: scale(1.03);
      }
    }
  }

  .image-container {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transform: scale(1.0);
    transition: all 0.6s ease-in-out;
  }

  img {
    width: 100%;
  }

  .element-hover-container {
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
    -webkit-transition: opacity 0.2s linear;
    -moz-transition: opacity 0.2s linear;
    -ms-transition: opacity 0.2s linear;
    -o-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;

    .element-hover-content {
      width: 100%;
      position: absolute;
      left: 0;
      padding: 15px;
      top: 50%;
      transform: translateY(-50%);

      .title {
        font-size: 14px;
        color: #fff;
        font-weight: bold;

        &:after {
          content: "";
          display: block;
          width: 58px;
          height: 4px;
          margin: 13px auto 0 auto;
        }

        &.sciences-sante {
          &:after {
            background-color: $color-scientifique;
          }
        }

        &.ages-societe {
          &:after {
            background-color: $color-societe;
          }
        }

        &.culture {
          &:after {
            background-color: $color-culture;
          }
        }
      }

      .name {
        margin-top: 18px;
        color: #fff;
        font-size: 15px;
      }
    }
  }

}

.project-image {
  display: block;
  margin: 0 auto;
}

.not-found {
  text-align: center;
  font-size: 30px;
  margin-top: 80px;

  h2 {
    font-size: 50px;
    margin-bottom: 35px;
  }

  .btn {
    font-size: 16px;
    margin-top: 50px;
  }
}

#page-finance {
  h2 {
    font-weight: bold;
    font-size: 26px;
  }

  .gray-part {
    margin-top: -40px;
    margin-bottom: 100px;
    padding: 75px 0 60px 0;
    background-color: #F5F5F5;

    h2 {
      margin-bottom: 45px;
    }

    .chiffre {
      color: #464849;
      text-align: center;
      font-size: 40px;
      font-weight: bold;
      line-height: 0.7;
      margin-bottom: 50px;

      &:first-child {
        margin-top: 40px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .label {
        font-size: 16px;
        color: #464849;
      }

      .currency {
        font-size: 36px;
      }
    }
  }

  .tabs-chiffres {
    margin-top: 30px;
    margin-bottom: 50px;

    .tabs-chiffres-header {
      display: flex;

      .tab-chiffres-header {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border: 1px solid black;
        border-bottom: none;
        padding: 10px 20px;
        text-transform: uppercase;
        font-weight: 800;
        font-size: 16px;
        margin-right: 5px;
        cursor: pointer;

        &.culture {
          border-color: $color-culture;
        }

        &.age {
          border-color: $color-societe;
        }

        &.sciences-sante {
          border-color: $color-scientifique;
        }

        &.inter {
          border-color: $color-interdomaines;
        }

        &.active {
          color: white;

          &.culture {
            background-color: $color-culture;
          }

          &.age {
            background-color: $color-societe;
          }

          &.sciences-sante {
            background-color: $color-scientifique;
          }

          &.inter {
            background-color: $color-interdomaines;
          }
        }
      }
    }

    .tab-chiffres-content {
      color: white;
      height: 250px;
      border-top-right-radius: 20px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      padding: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.culture {
        background-color: $color-culture;
      }

      &.age {
        background-color: $color-societe;
      }

      &.sciences-sante {
        background-color: $color-scientifique;
      }

      &.inter {
        background-color: $color-interdomaines;
      }

      &:not(.active) {
        //can't display none because chart is not loaded
        opacity: 0;
        position: absolute;
        left: -200%;
      }

      .chiffre {
        text-align: center;
        font-size: 40px;
        font-weight: bold;
        line-height: 0.7;

        .label {
          font-size: 13px;
        }

        &.percent {
          font-size: 60px;
          line-height: 0.45;
          width: 200px; //to have fix graph size
          position: relative;

          .label {
            margin-left: 130px;
          }

          .rounded-graph {
            position: absolute;
            left: 70px;
            top: -62px;
            transform: rotate(125deg);
            width: 190px !important;
            height: 190px !important;
          }
        }
      }
    }
  }

  .hide-bars {
    display: none;
  }

  .graph {
    .bar-container {
      padding: 15px 0;

      .row {
        display: flex;
        align-items: center;
      }

      .bar {
        height: 80px;
        background-color: black;
        transition: 1s all;
        max-width: 100%;
        color: white;
        font-weight: bold;
        padding: 28px 10px;

        &.bar-total {
          background-color: transparent;
          padding: 0;

          .sub-bar {
            height: 100%;
            float: left;
            padding: 28px 10px;
            margin-left: 0;
            transition: 1s all;

            &:nth-child(1) {
              background-color: #00569D;
            }

            &:nth-child(2) {
              background-color: #EFBF00;
            }

            &:nth-child(3) {
              background-color: #E74011;
            }

            &:nth-child(4) {
              background-color: #575756;
            }
          }
        }
      }

      &:nth-child(1) .bar {
        background-color: #00569D;
      }

      &:nth-child(2) .bar {
        background-color: #EFBF00;
      }

      &:nth-child(3) .bar {
        background-color: #E74011;
      }

      &:nth-child(4) .bar {
        background-color: #575756;
      }
    }

    .total {
      font-weight: bold;
      position: absolute;
      right: 0;

      &:after {
        content: "";
        position: absolute;
        right: 15px;
        height: 10px;
        bottom: 19px;
        width: 2px;
        background-color: #575756;
      }
    }
  }

  .graph-top {
    .stats {
      min-height: 470px;

      &:nth-child(2) .title {
        background-color: #00569D !important;
      }

      &:nth-child(3) .title {
        background-color: #EFBF00 !important;
      }

      &:nth-child(4) .title {
        background-color: #E74011 !important;
      }

      &:nth-child(5) .title {
        background-color: #575756 !important;
      }
    }

    .graph-arrow {
      font-size: 30px;
      cursor: pointer;
      margin-right: 10px;

      &.right {
        margin-left: 10px;
        margin-right: 0px;
      }

    }
  }


  #section-chiffres {
    margin-bottom: 110px;

    #chiffres-intro {
      font-size: 15px;
    }

    #chiffres-graph {
      margin-top: 110px;

      .graph-top {
        .graph-top-left {
          .title {
            font-weight: bold;
            font-size: 20px;
            color: white;
            text-transform: uppercase;
            background-color: black;
            padding: 9px 18px;
            display: inline-block;
          }

          .chiffre {
            margin-top: 55px;

            .nombre {
              font-size: 38px;
              color: black;
            }

            .description {
              font-size: 23px;
            }
          }
        }

        .graph-top-right {
          #chiffres-graph-chart {
            max-width: 100% !important;
          }
        }
      }

      .graph-bottom {
        margin-top: 110px;

        .nav {
          .onglet-trigger {
            text-transform: uppercase;
            padding: 19px 61px;
            display: block;
            float: left;

            &.culture {
              color: $color-culture;
            }

            &.age {
              color: $color-societe;
            }

            &.sciences-sante {
              color: $color-scientifique;
            }

            &.inter {
              color: $color-interdomaines;
            }

            &.active {
              &.culture {
                color: white;
                background-color: $color-culture;
              }

              &.age {
                color: white;
                background-color: $color-societe;
              }

              &.sciences-sante {
                color: white;
                background-color: $color-scientifique;
              }

              &.inter {
                color: white;
                background-color: $color-interdomaines;
              }
            }

            &:hover {
              &.inactive {
                background-color: #f9f9f9;
              }

              text-decoration: none;
            }
          }
        }

        .content-graph-onglets {
          padding: 100px 80px;
          display: none;

          &.culture {
            background-color: rgba($color-culture, 0.1);
          }

          &.age {
            background-color: rgba($color-societe, 0.1);
          }

          &.sciences-sante {
            background-color: rgba($color-scientifique, 0.1);
          }

          &.inter {
            background-color: rgba($color-interdomaines, 0.1);
          }

          &.active {
            display: block;
          }

          .chiffre {
            text-align: center;

            .nombre {
              font-size: 43px;
              color: black;
            }

            .description {
              font-size: 21px;
            }
          }
        }
      }
    }

  }

  @media (max-width: 1200px) {
    .graph-top-right {
      display: none
    }

    .graph-top-left {
      flex: 100%;
      max-width: 100%;
      width: 100%;
      text-align: center;
    }

    .graph-arrow {
      position: absolute;
      top: calc(50% - 15px);
      left: 15px;

      &.right {
        right: 15px;
        left: auto !important;
      }
    }

    .stats {
      min-height: 0 !important;
    }
  }

}

.types-clickable {
  font-size: 15px !important;

  img {
    max-width: 30px;
    margin-right: 5px;
    max-height: 25px;
  }
}

.opaque {
  opacity: 0.3;
}

.normal {
  opacity: 1;
}

.types_horizontal_listing {
  margin: 40px 0 0 0;
  padding: 0;
  list-style: none;
  max-width: 827px;
  max-width: 724px;
  display: inline-block;
  float: left;

  li {
    float: left;
    display: inline;

    div {
      position: relative;
      top: 19px !important;
      padding-right: 15px;
    }

    &.active {
      a {
        color: darken(#575756, 10%);
        text-decoration: none;
        opacity: 1;
      }

      svg {
        g {
          fill: darken(#00569D, 15%);

          path {
            fill: darken(#00569D, 15%);
          }

          circle {
            fill: darken(#00569D, 15%);
          }
        }
      }
    }

    a {
      display: block;
      font-size: 18px;
      color: #575756;
      /* opacity: 0.3; */

      svg {
        max-width: 30px;
        max-height: 25px;
        top: 5px;
        position: relative;
        right: 5px;

        g {
          fill: #00569D;
          transition: fill .4s linear;

          path {
            fill: #00569D;
            transition: fill .4s linear;
          }

          circle {
            fill: #00569D;
            transition: fill .4s linear;
          }
        }
      }
    }

    &.year {
      a {
        padding: 10px 25px;

        &:hover {
          color: darken(#A3A3A3, 10%);
          text-decoration: none;
          background: #F5F5F5;
        }
      }
    }

    &.type {
      a {
        padding: 10px 15px;
        font-weight: 100;
        transition: all .2s ease;

        &:hover {
          font-weight: 900;
          text-decoration: none;
        }
      }
    }

    &.more {
      margin: 10px 0 0 25px;
    }
  }
}

.my-year-bourse {
  font-size: 28px;
  color: #555759;
  margin-top: 20px;
  margin-bottom: 40px;
  display: inline;
}

.bourse-related h2 {
  margin-left: 15px;
}

#bourses-lists {

  .nav-pills>li {
    float: none !important;
  }



  .nav-pills>li.active>a,
  .nav-pills>li.active>a:focus,
  .nav-pills>li.active>a:hover {
    background: none !important;
  }


}

.homepage-news {
  .slider-navigation {
    top: 120px;
    left: 15px;
    transform: none;
  }

  .mobile-slider {
    margin-top: 30px;
  }

  .dots {
    display: block;
    margin-top: -100px;

    .slick-dots {
      position: inherit;

      li {
        width: auto;
        height: auto;
        margin: 0 15px 0 0;
        -webkit-transition: opacity 0.2s linear;
        -moz-transition: opacity 0.2s linear;
        -ms-transition: opacity 0.2s linear;
        -o-transition: opacity 0.2s linear;
        transition: opacity 0.2s linear;

        button {
          margin: 0;
          padding: 0;
          background-image: url('../../images/slider_project_dot.png');
          width: 10px;
          height: 10px;

          &:before {
            content: "";
          }
        }

        &:hover {
          opacity: 0.8;
        }

        &.slick-active {
          button {
            width: 17px;
            height: 17px;
            background-image: url('../../images/slider_project_dot_current.png');
          }
        }
      }
    }
  }
}

.mobile-nav {
  display: none;
}

.template-logo {
  ul.nav {
    margin-top: 70px;
  }

  .card-report {
    min-height: 350px;

    h2 {
      margin-top: 0;
      padding: 30px 40px 0 40px !important;
      text-align: left;
      border-top: 1px solid #D8D8D8;
      font-size: 17px !important;
    }

    a.download {
      background: url(../../images/logo-download.png) no-repeat !important;
    }

    .file {
      margin-top: 25px;
      margin-left: 40px;
      padding-left: 0 !important;
      display: block !important;

      &:first-of-type {
        margin-top: 0;
      }
    }

    small {
      display: block;
      text-align: left;
      padding-left: 46px;
      color: #D8D8D8;
      font-size: 12px;
    }
  }
}

.vimeo-container {
  position: relative;
}

.vimeo-link,
.vimeo-link:hover,
.vimeo-link:active {
  text-decoration: none;
  position: absolute;
  top: 1px;
  transform: translateY(-100%);
  right: 0;
  background-color: #2D2D2D;
  padding: 10px;
  transition: .4s all;
  color: white !important;

  img {
    margin-right: 3px;
  }
}

.vimeo-link:hover {
  background-color: #2d2d2de6;
}

.template-rendez-vous {
  padding-top: 40px;

  .intro {
    font-size: 24px;
    word-break: break-word;
    color: #575756;
    margin-bottom: 70px;
  }

  .rendez-vous-container {
    background-color: #F5F5F5;
    margin-bottom: -60px;
    padding-bottom: 60px;
  }

  .filters {
    margin-top: 80px;
    margin-bottom: 50px;

    div {
      float: left;
      margin: 0 20px;
      position: relative;
      cursor: pointer;
      color: #888888;
      font-size: 18px;

      &.active {
        font-weight: bold;
      }

      &.all {
        margin-left: 0;
      }

      &:not(:last-of-type):after {
        content: '';
        position: absolute;
        right: -20px;
        height: 20px;
        width: 1px;
        background-color: #D8D8D8;
      }
    }
  }

  .year-group {
    margin-bottom: 50px;
    position: relative;

    a,
    a:hover {
      color: #575756;
      text-decoration: none;
    }

    .year {
      font-size: 27px;
      color: #575756;
      margin-bottom: 15px;
    }

    .img {
      height: 225px;
      background-position: center;
      background-size: cover;
    }

    .title {
      padding: 20px 45px 25px 20px;
      font-size: 16px;
      word-break: break-word;
      font-weight: bold;
    }

    .text {
      margin-top: 5px;
      font-size: 14px;
      color: #888888;
    }

    position: relative;

    .prev,
    .next {
      position: absolute;
      right: -50px;
      top: 50%;
      transform: translateY(-50%) rotate(180deg);
      cursor: pointer;

      img {
        transition: .4s all;
      }

      &:hover img {
        transform: scale(1.1);
      }
    }

    .prev {
      transform: translateY(-50%);
      left: -50px;
      right: auto;
    }

    .see-more {
      position: absolute;
      right: 28px;
      bottom: 15px;
      width: 28px;
      height: 28px;
      background-image: url('../../images/rendez_vous_arrow_see_more.png');
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;
    }

    .slick-track {
      margin: 0;
      padding-bottom: 3px;
    }

    .container-slide {
      background-color: white;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.11);
    }

    .slide {
      .img {
        position: relative;
      }

      .icon-type {
        border-radius: 15px;
        position: absolute;
        bottom: 15px;
        left: 15px;
        background-color: rgba(0, 0, 0, 0.8);
        padding: 10px 15px;
        color: white;

        img {
          float: left;
          margin-right: 10px;
        }
      }
    }
  }
}

#audio-modal {
  background: rgba(0, 0, 0, 0.8);

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 80px;
    max-width: 500px;
    width: 90%;

    .close {
      color: white;
      opacity: 0.7;
      text-shadow: none;
      margin-bottom: 10px;
    }

    .player-time {
      color: white !important;
    }
  }
}

// CSS FROM https://www.jqueryscript.net/other/Minimalist-Custom-Audio-Player.html
.ppq-audio-player .play-pause-btn .play-pause-icon:after {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

.ppq-audio-player .play-pause-btn .play-pause-icon:after,
.ppq-audio-player.player-playing .play-pause-icon:after {
  background-image: url('../../images/audio-player.png') !important;
  background-size: 37px 18px
}

.ppq-audio-player {
  line-height: 111px;
  position: relative;
  overflow: hidden;
  height: 111px;
  margin: 0 auto;
  background: transparent !important
}

.ppq-audio-player audio {
  position: absolute;
  vertical-align: baseline
}

.ppq-audio-player .play-pause-btn {
  float: left;
  margin: 29px 0 0 12px
}

.ppq-audio-player .play-pause-btn .play-pause-icon {
  position: relative;
  display: block;
  width: 47px;
  height: 47px;
  border: 3px solid #00569D !important;
  border-radius: 100%;
  background-color: #fff
}

.ppq-audio-player .play-pause-btn .play-pause-icon:after {
  display: block;
  content: "";
  background-position: 0 0;
  width: 17px;
  height: 18px
}

.ppq-audio-player.player-playing .play-pause-icon:after {
  background-position: -25px 0;
  width: 12px;
  height: 17px
}

.ppq-audio-player .player-time {
  float: left;
  width: 51px;
  margin-right: 8px;
  text-align: right
}

.ppq-audio-player .player-time-duration {
  float: right;
  margin: 0 0 0 8px;
  text-align: left
}

.ppq-audio-player .player-bar {
  position: relative;
  overflow: hidden;
  height: 5px;
  margin-top: 53px;
  background-color: #fff
}

.ppq-audio-player .player-bar .player-bar-loaded {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  background: #ddd
}

.ppq-audio-player .player-bar .player-bar-played {
  position: absolute;
  top: 0;
  left: -1px !important;
  width: 0;
  height: 100%;
  border-radius: 3px;
  background: #00569D !important
}

// END CSS FROM https://www.jqueryscript.net/other/Minimalist-Custom-Audio-Player.html

.content-single-rendez-vous {
  .iframe-container {
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
  }

  .iframe-container iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .back {
    color: white;
    text-decoration: none;
    background-color: #B7B7B7;
    padding: 10px 15px;
    font-size: 13px;
  }

  .title {
    margin-top: 30px;
    font-size: 37px;
    font-weight: bold;
    word-break: break-word;
  }

  .subtitle {
    font-size: 20px;
    color: #888888;
    margin-bottom: 30px;
    word-break: break-word;
  }

  .content {
    margin-top: 30px;
    word-break: break-word;
    clear: both;
  }

  .video {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    height: auto;
    margin-bottom: 15px;
  }

  .video iframe,
  .video object,
  .video embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .ppq-audio-player {
    width: 400px;
    float: left;
  }

  .share-title {
    clear: both;
    margin-top: 50px;
  }

  .social-share {
    clear: both;
    margin-top: 15px;

    li {
      margin-right: 13px;

      a {
        display: inline-block;
        color: white;
        border-radius: 100%;
        font-size: 16px;

        .fa {
          color: white;
        }
      }

      &.facebook {
        a {
          background-color: #3b5a99;
          padding: 4px 10px;
        }
      }

      &.twitter {
        a {
          background-color: #5b8ec8;
          padding: 4px 8px;
        }
      }

      &.linkedin {
        a {
          background-color: #127bb7;
          padding: 4px 8px;
        }
      }

      &.google {
        a {
          background-color: #d44a36;
          padding: 4px 5px;
        }
      }

      &.email {
        a {
          background-color: #575756;
          padding: 4px 7px;
        }
      }
    }
  }

  .realted-title {
    margin: 0 50px 30px 50px;
    padding: 0 10px;
    text-align: center;
    background-color: white;
    word-break: break-word;
    position: relative;
  }

  .related:before {
    z-index: -1;
    content: '';
    position: absolute;
    top: 32px;
    left: 15px;
    width: calc(100% - 30px);
    height: 2px;
    background-color: #00569D;
  }

  .related.societe:before {
    background-color: #EFBF00 !important;
  }

  .related.sciences-sante:before {
    background-color: #E74011 !important;
  }

  .related.societe #related-next {

    svg,
    path {
      fill: #EFBF00;
    }
  }

  .related.culture #related-next {

    svg,
    path {
      fill: #00569D;
    }
  }

  .related {
    padding-top: 15px;

    #related-next {
      text-align: center;

      svg {
        cursor: pointer;
      }
    }

    .related-post {
      position: relative;

      .slick-prev,
      .slick-next {
        display: none !important;
      }
    }

    .slide {
      position: relative;

      .img {
        position: relative;
        height: 145px;
        background-position: center;
        background-size: cover;
      }

      .icon-type {
        border-radius: 15px;
        position: absolute;
        bottom: 15px;
        left: 15px;
        background-color: rgba(0, 0, 0, 0.8);
        padding: 10px 15px;
        color: white;

        img {
          float: left;
          margin-right: 10px;
        }
      }

      .title {
        margin-top: 15px;
        font-size: 16px;
        font-weight: bold;
        word-break: break-word;
        padding-right: 30px;
        margin-bottom: 25px;
      }

      .text {
        margin-top: 5px;
        font-size: 14px;
        color: #888888;
      }

      .see-more {
        position: absolute;
        right: 0;
        bottom: 15px;
        width: 28px;
        height: 28px;
        background-image: url('../../images/rendez_vous_arrow_see_more.png');
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
      }

      a,
      a:hover {
        text-decoration: none;
        color: #575756;
      }
    }
  }
}

.projects-btn.see-all,
.all-news.see-all {
  background-image: none !important;
  padding-right: 0 !important;
  font-size: 14px !important;
  position: relative !important;

  img {
    margin-left: 15px;
  }

  &:hover {
    text-decoration: none !important;
  }
}

#projects-archive {
  padding-top: 50px;

  .project-thumbnail .img {
    width: 100%;
    height: 250px;
    background-size: cover;
    background-position: center;
  }
}

.container-reports .year-filter {
  margin-right: 40px;
  margin-top: 70px !important;
  z-index: 9999;
}

.container-reports .group-cat {
  position: relative;
}

.press-slider-next {
  position: absolute;
  top: 50%;
  right: -5px;
  z-index: 999;
  cursor: pointer;
}

.press-slider-prev {
  position: absolute;
  top: 50%;
  left: -25px;
  transform: rotate(180deg);
  z-index: 999;
  cursor: pointer;
}

.press-slider {
  .slick-track {
    height: 100% !important;
    display: inline-block;
  }

  .slide {
    padding-right: 20px;
    height: 435px;

    &:last-of-type {
      padding-right: 0;
    }

    .card-report {
      width: 100%;
      height: 100%;
      text-align: left !important;
      padding-bottom: 10px !important;
      position: relative;
    }

    h2 a {
      font-weight: bold !important;
      font-size: 15px !important;
    }

    .download {
      background: url(../../images/logo-download.png) no-repeat !important;
      margin: 0 10px !important;
      padding-left: 35px !important;
      font-size: 15px !important;
      padding-bottom: 2px !important;
      background-position-y: 8px !important;
    }

    .bottom-ddl {
      position: absolute;
      bottom: 20px;

      .size {
        color: #D8D8D8;
        font-size: 13px;
      }

      a {
        font-size: 13px !important;
        min-height: 41px;
      }
    }

    .see {
      margin: 0 10px !important;
      font-size: 13px !important;
      padding-left: 5px;
      width: 100%;
      min-height: 29px !important;

      i {
        margin-right: 3px;
      }
    }

    .theme {
      background-color: #F0F0F0;
      color: #575756;
      padding: 3px 10px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  .slick-dots {
    li {
      width: 17px !important;

      button {
        background-color: black;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        margin-top: 15px;
        opacity: 0.25;
        transition: .4s all;

        &:before {
          content: none;
        }
      }

      &.slick-active button {
        width: 17px;
        height: 17px;
        opacity: 1;
      }
    }
  }
}

.page-template-template-culture,
.page-template-template-age,
.page-template-template-scientifique {
  #intro .content {
    min-height: 330px;
    line-height: 1.4;
  }

  .soutien-blocs {
    padding: 0 30px;

    h2 {
      font-weight: bold;
    }

    
    .blocs {
      margin-top: 30px;
      display: flex;
      flex-wrap: wrap;
      
      .col-lg-4 {
        
        @media (max-width:1199px) {
          width: 100%
        }
      }
      .bloc {
        padding: 35px 20px 150px 20px;
        background-color: rgba(#746A67, 0.05);
        position: relative;
        line-height: 1.7;
        min-height: 450px;
        margin-bottom: 25px;
        height: 100%;

        @media (max-width:1199px) {
          height: auto;
        }

        .top-button {
          position: absolute;
          top: 0;
          right: 0;
          padding: 5px 15px;
          border-radius: 40px;
          color: white;
          transform: translateY(-50%);
        }

        .title {
          font-weight: bold;
          font-size: 16px;
          min-height: 55px;
        }

        .description {
          font-size: 15px;
          margin-top: 15px;
        }

        .bottom-part {
          position: absolute;
          bottom: 30px;
          left: 20px;
          width: calc(100% - 40px);

          .links {
            position: absolute;
            bottom: 65px;
            left: 0;

            .link {
              margin-right: 15px;
              margin-top: 10px;
              color: #575756;
              text-decoration: none;
              position: relative;
              white-space: nowrap;
              display: inline-block;

              &:after {
                content: '';
                position: absolute;
                bottom: -5px;
                left: 0;
                width: 0;
                height: 1px;
                background-color: #575756;
                transition: .4s all;
              }

              &:hover:after {
                width: calc(100% + 5px);
              }
            }
          }

          .button {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 16px;
            color: white;
            padding: 10px 15px;
            text-align: center;
            text-decoration: none;
            display: block;
          }
        }
      }
    }

    &.culture {
      .colored-light {
        background-color: rgba($color-culture, 0.05);
      }

      .colored-link {
        color: $color-culture !important;

        &:after {
          background-color: $color-culture !important;
        }

        .gear svg,
        .gear path {
          fill: $color-culture;
        }

        .medal circle {
          stroke: $color-culture;
        }

        .medal rect {
          stroke: $color-culture;
          fill: $color-culture;
        }

        .link-icon,
        .link-icon path {
          stroke: $color-culture;
          fill: $color-culture;
        }
      }

      .colored {
        background-color: $color-culture;
      }
    }

    &.societe {
      .colored-light {
        background-color: rgba($color-societe, 0.05);
      }

      .colored-link {
        color: $color-societe !important;

        &:after {
          background-color: $color-societe !important;
        }

        .gear svg,
        .gear path {
          fill: $color-societe;
        }

        .medal circle {
          stroke: $color-societe;
        }

        .medal rect {
          stroke: $color-societe;
          fill: $color-societe;
        }

        .link-icon,
        .link-icon path {
          stroke: $color-societe;
          fill: $color-societe;
        }
      }

      .colored {
        background-color: $color-societe;
      }
    }

    &.scientifique {
      .colored-light {
        background-color: rgba($color-scientifique, 0.05);
      }

      .colored-link {
        color: $color-scientifique !important;

        &:after {
          background-color: $color-scientifique !important;
        }

        .gear svg,
        .gear path {
          fill: $color-scientifique;
        }

        .medal circle {
          stroke: $color-scientifique;
        }

        .medal rect {
          stroke: $color-scientifique;
          fill: $color-scientifique;
        }

        .link-icon,
        .link-icon path {
          stroke: $color-scientifique;
          fill: $color-scientifique;
        }
      }

      .colored {
        background-color: $color-scientifique;
      }
    }
  }

  #footer {
    margin-top: 0;
  }
}

.page-template-template-culture #bourses-prizes-container.in-search-mode {
  .filters {
    margin-bottom: 30px;
  }
}

#bourses-prizes-container {
  background-color: #F5F5F5;
  padding: 40px 0 60px 0;
  min-height: 300px;

  .spacer-top {
    padding-top: 120px;
  }

  .filters {
    float: left;
    display: flex;
    padding-top: 7px;

    .filter {
      margin-right: 25px;
      font-size: 15px;
      cursor: pointer;
      display: flex;
      align-items: center;

      .icon {
        margin-right: 10px;

        svg {
          max-width: 18px;
          max-height: 18px;
          float: left;
          fill: $color-culture;

          path,
          circle,
          rect {
            fill: $color-culture;
          }
        }
      }
    }
  }

  .search-container {
    margin-bottom: 30px;

    .search {
      float: right;
      border: 0;
      outline: 0;
      background-color: transparent;
      border-bottom: 1px solid #575756;
      padding: 12px 15px;
      width: 350px;
      color: #575756;
    }

    .search::placeholder {
      color: #575756;
    }

    .fa {
      float: right;
      font-size: 20px;
      border-bottom: 1px solid #575756;
      padding: 11.5px;
    }
  }

  &.in-search-mode {

    .subtitle,
    .display-all,
    .years-container {
      display: none;
    }

    .spacer-top {
      margin-top: 0;
    }

    .prizes-container {
      min-height: 0 !important;
    }
  }

  .container {
    position: relative;
  }

  .years-container {
    position: absolute;
    top: 120px;
    left: -30px;
    transform: translateX(-100%);

    .years-list {
      overflow: hidden;
      height: 185px;

      .year {
        font-size: 28px;
        font-weight: 500px;
        color: #A3A3A3;
        transition: .4s all;
        cursor: pointer;
        margin-bottom: 10px;

        &.active {
          color: #464849;
          font-weight: bold;
        }
      }

      &.open {
        height: 100%;
        max-height: 510px;
        overflow-y: scroll;
      }
    }

    .more {
      text-align: center;
      color: #464849;
      font-size: 18px;
      margin-top: 5px;
      cursor: pointer;
    }
  }

  .prizes-bourses-list {
    .prizes-of-year {
      display: none;

      &.active {
        display: block;
      }

      h2 {
        margin-top: 0;
        font-weight: bold;
      }

      .subtitle {
        margin-bottom: 40px;
      }

      .prizes {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 40px 30px;
        margin-bottom: 25px;

        &.culture-list {
          grid-template-columns: repeat(4, 1fr);
        }

        .prize-card,
        .prize-card-culture {
          display: none;

          &.show {
            display: block;
          }
        }

        .display-all {
          text-align: center;
          grid-column: span 2;
          cursor: pointer;
        }
      }

      .bourses {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 40px 30px;
        margin-bottom: 25px;

        .bourse-card {
          display: none;

          &.show {
            display: block;
          }
        }
      }
    }
  }
}

#bourses-prizes-container {

  &.age,
  &.societe {

    .prize-card,
    .bourse-card,
    .prize-card-culture {
      .category {
        background-color: $color-societe;
      }

      &:hover {
        background-color: rgba($color-societe, .4);
      }
    }
  }

  &.sciences-sante {

    .prize-card,
    .bourse-card,
    .prize-card-culture {
      .category {
        background-color: $color-scientifique;
      }

      &:hover {
        background-color: rgba($color-scientifique, .4);
      }
    }
  }

  &.culture {

    .prize-card,
    .bourse-card,
    .prize-card-culture {
      .category {
        background-color: $color-culture;
      }

      &:hover {
        background-color: rgba($color-culture, .4);
      }
    }
  }
}

.prize-card {
  background-color: white;
  transition: .4s all;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.11);

  a,
  a:hover {
    text-decoration: none;
  }

  .image {
    height: 175px;
    background-position: center;
    background-size: cover;
    position: relative;

    .category {
      position: absolute;
      top: 0;
      right: 35px;
      padding: 10px 15px;
      color: white;
      font-weight: bold;
      font-size: 16px;
      text-transform: uppercase;
    }
  }

  .details {
    padding: 20px;
    height: calc(100% - 175px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h4 {
      font-weight: bold;
      font-size: 22px;
      color: $color-main;
      margin-top: 0;
    }

    .authors {
      margin-top: 15px;
      font-size: 16px;
      color: $color-main;
    }
  }
}

.bourse-card,
.prize-card-culture {
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.11);
  transition: .4s all;

  a,
  a:hover {
    text-decoration: none;
  }

  .image {
    height: 250px;
    background-position: center 0;
    background-size: cover;
    position: relative;
  }

  .details {
    padding: 15px;
    padding-bottom: 35px;
    position: relative;
    height: calc(100% - 250px);

    h4 {
      font-weight: bold;
      font-size: 18px;
      color: $color-main;
      margin-top: 0;
      margin-bottom: 3px;
      max-width: calc(100% - 30px);
    }
  }

  .function {
    font-size: 15px;
    color: $color-main;
    max-width: calc(100% - 35px);
  }

  .icon {
    background-color: $color-culture;
    display: inline-block;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    right: 10px;

    svg {
      max-height: 60%;
      max-width: 60%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      g,
      path,
      circle {
        fill: white;
      }
    }
  }

  .see-more {
    text-align: right;
    font-size: 12px;
    color: $color-main;
    margin-top: 15px;
    position: absolute;
    right: 15px;
    bottom: 15px;
  }
}

.single-bourse,
.single-prize,
.page-template-template-prize,
.page-template-template-bourse {
  #footer {
    margin-top: 0;
  }
}

.page-template-template-contact,
.single-post {
  #header {
    background: white;

    .menu-item-has-children {
      a {
        color: #575756 !important;
      }

      ul a {
        color: white !important;
      }

      &.current-menu-item a,
      &.current-menu-parent a {
        color: white !important;
      }
    }

    .search {
      color: #575756;
    }

    .logo.white img {
      display: none;
    }

    .logo.color img {
      display: block;
    }
  }
}

.single-post {
  #banner {
    height: 470px;
    margin-top: 135px;
  }

  .back {
    color: white;
    text-decoration: none;
    background-color: #B7B7B7;
    padding: 10px 17px;
    font-size: 13px;
    text-transform: uppercase;
    margin-bottom: 10px;
    display: inline-block;
  }

  .container.smaller {
    padding: 0 100px;
  }

  .related-posts {
    margin-top: 50px;
  }
}

.page-template-template-foundation {
  .template-foundation-content {
    line-height: 1.8;

    .values-container {
      margin: 40px 0;
    }

    .values {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 15px;
      margin-top: 20px;

      .value {
        background-color: #F8F8F8;
        padding: 35px 30px;

        .title {
          font-size: 24px;
          margin-bottom: 15px;
        }
      }
    }
  }
}

.single-project {
  .back {
    color: white;
    text-decoration: none;
    background-color: #B7B7B7;
    padding: 10px 17px;
    font-size: 13px;
    text-transform: uppercase;
    margin-bottom: 10px;
    display: inline-block;
  }

  .related-posts-container {
    h3 {
      margin-bottom: 50px;
    }

    .blog-posts-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 80px 60px;

      .blog-post {
        margin-bottom: 0;
      }
    }
  }
}

.template-events {
  .year-groups {
    margin-top: 80px;
  }

  .year-group {
    .year {
      float: left;
    }

    .more {
      float: right;

      svg {
        margin-top: 8px;
        float: right;
      }

      span {
        margin-top: 12px;
        display: inline-block;
        margin-right: 13px;
      }
    }

    .slider-rendez-vous {
      clear: both;
    }

    &.culture {

      .year,
      .more {
        color: $color-culture;
        font-weight: bold;

        svg {
          path {
            stroke: $color-culture;
            fill: $color-culture;
          }

          ellipse {
            stroke: $color-culture;
          }
        }
      }
    }

    &.ages-societe {

      .year,
      .more {
        color: $color-societe;
        font-weight: bold;

        svg {
          path {
            stroke: $color-societe;
            fill: $color-societe;
          }

          ellipse {
            stroke: $color-societe;
          }
        }
      }
    }

    &.sciences-sante {

      .year,
      .more {
        color: $color-scientifique;
        font-weight: bold;

        svg {
          path {
            stroke: $color-scientifique;
            fill: $color-scientifique;
          }

          ellipse {
            stroke: $color-scientifique;
          }
        }
      }
    }
  }
}

.page-template-template-press,
.archive.tax-presse-categories {
  .intro {
    margin-top: 40px;
  }

  .press-posts-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 70px 40px;

    .blog-post {
      margin-bottom: 0;

      .blog-post-thumbnail {
        .image {
          height: 250px;
          background-size: cover;
          background-position: center;
        }
      }

      .title-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 15px 0 20px 0;

        h2 {
          margin-top: 0;
          margin-bottom: 0;
          padding-right: 10px;
          flex-basis: calc(100% - 30px);

          a {
            font-size: 21px;
          }
        }

        .image-container {
          flex-basis: 30px;
        }
      }

      .links-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        div {
          margin-bottom: 9px;
        }

        a {
          color: #575756;
        }
      }
    }
  }

  .filters {
    .filters-categories {
      float: left;
    }

    .year-filter {
      margin-top: 0 !important;
    }
  }
}

.single-presse {
  .back {
    color: white;
    text-decoration: none;
    background-color: #B7B7B7;
    padding: 10px 17px;
    font-size: 13px;
    text-transform: uppercase;
  }

  .back-and-links {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .links {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .link {
        margin-left: 25px;
      }

      a {
        color: #575756;
      }
    }
  }

  .content h1 {
    margin-bottom: 20px;
  }

  .share-title {
    font-size: 14px;
    margin-bottom: -30px;
  }
}

.copyright-video {
  float: right;
  transform: translate(15px, calc(-100% - 3px));
  font-size: 14px;
  color: white;
}

.page-template-template-events {
  .intro {
    font-size: 18px;
  }
}