.sub-organisation{
  width: 100%;
  background: #FBFBFB;
  padding: 20px 0 80px 0;
  margin-bottom: -80px;
}

.margin-bottom {
	padding-bottom: 50px;
}

.container-organisation{

    .title-container {
        height: 115px;
        position: relative;
    }

  h2 {
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    min-width: 200px;
    font-size: 24px;
    &.main-title {
        font-size: 28px;
    }
    font-weight: normal;
    color: #555759;
    padding-bottom: 15px;

  }

  .bloc-organisation {
	  &.left {
		  padding-right: 60px;
	  }
	  &.right {
		  padding-left: 60px;
	  }
  }

  .bloc-gouvernance{
    ol{
      padding-left: 7px;
      li{
        margin-bottom: 20px;
    }
    }
  }

  .membre-card{
    text-align: center;


    &.small {
	    height: 260px;
	}
	&.big {
		height: 330px;
		margin-bottom: 50px;
	}

    img{
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      border-radius: 100%;
      margin: 0 auto;
    }

    h3{
      font-size: 18px;
      font-weight: normal;
      color: #575756;
      margin: 40px 0 5px 0;
      padding: 0;
    }

    .fonction{
      font-size: 14px;
      font-weight: bold;
      color: #575756;
    }
  }
}
