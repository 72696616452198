/* Small devices (tablets, 768px and up) */
@media (max-width: $screen-xs-max) {}

@media (max-width: $screen-sm-max) {}

/* Medium devices (desktops, 992px and up) */
@media (max-width: $screen-md-max) {
	#bourses-lists {
		.title {
			&.culture {
				padding: 15px 15px 35px !important;
			}
		}

		.boursier-taxonomy {
			top: 28px !important;
		}

	}
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: $screen-lg-min) {

	.homepage {

		.homepage-blocs {

			.homepage-bloc {

				.project-container {

					.project {

						.name {
							min-height: 155px;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 1440px) {

	#bourses-prizes-container {

		.spacer-top {
			padding-top: 50px;
		}

		.years-container {
			position: initial;
			transform: none;
			margin-bottom: 30px;

			.years-list {
				float: left;
				display: flex;
				flex-wrap: wrap;
				overflow: hidden;
				width: 315px;
				height: 50px;

				.year {
					margin-right: 15px;
				}

				&.open {
					height: auto;
					width: auto;
					overflow: none;
					float: none;
				}
			}

			.more {
				float: left;
			}
		}
	}

	.prizes-bourses-list {
		padding-top: 20px;
		clear: both;
	}

	#bourses-lists {

		.years-elements-row {
			padding-left: calc(100% - 800px);
		}

		#years {
			top: inherit;
			bottom: inherit;
			left: inherit;
			right: inherit;
			margin: 40px 0 0 0;

			ul {
				li {
					text-align: center;
					width: auto;
					display: block;
					margin-right: 20px;
					z-index: 99;

					&:last-child {
						position: relative;
						bottom: 4px;
					}

				}
			}
		}
	}

	#laureat {
		#years {
			position: inherit;
			top: inherit;
			bottom: inherit;
			left: inherit;
			right: inherit;
			margin: 40px 0 0 0;

			ul {
				li {
					text-align: center;
					width: auto;
					display: inline-block;
					margin-right: 20px;

					&:last-child {
						position: relative;
						bottom: 4px;
					}

				}
			}
		}
	}

	#laureat-content .section-title {
		margin-top: 20px;
	}
}

@media (max-width: 1199px) {

	#bourses-prizes-container {
		.filters {
			float: none;
			margin-bottom: 15px;
		}
	}

	#timeline {
		#timeline-container {
			.elements-timeline {
				.element-position {
					max-width: 35%;

					&.left {
						margin-left: 77px;
					}

					&.right {
						margin-right: 77px;
					}
				}
			}
		}
	}

	#search {
		.search-form {
			input[type="text"] {
				font-size: 75px;
			}
		}
	}

	.affix {
		width: 212px;
	}

	#footer .menu-footer-contact-container:before {
		left: -37px;
	}

	.template-contact .container {
		top: 55px;
	}

	#header #header-navigation a {
		font-size: 15px;
		padding: 14px 14px;
	}

	.section-videos .section-video-text {
		margin-bottom: 15px;
	}

	.section-videos .section-play {
		width: 55px;
		height: 55px;
		background-size: 100%;
	}

	.homepage .homepage-actions .homepage-action-left .description,
	.homepage .homepage-actions .homepage-action-right .description {
		padding: 20px 20px 20px 0;
		height: 115px !important;
	}

	.homepage .homepage-actions .homepage-action-left .link a,
	.homepage .homepage-actions .homepage-action-right .link a {
		margin-top: 20px;
		font-size: 15px;
		padding: 15px 10px;
	}

	.homepage .homepage-actions .homepage-action-left #bottom-vid-part-left {
		width: 505px;
	}

	.homepage .homepage-actions .homepage-action-right #bottom-vid-part-right {
		width: 485px;
	}

	.homepage .homepage-blocs .homepage-bloc .homepage-bloc-details {
		.homepage-bloc-details-content {
			transform: translateY(-50%);
		}
	}

	.homepage .homepage-blocs .homepage-bloc {
		width: 100% !important;
		float: none !important;
		display: block !important;
	}

	.homepage {

		.homepage-blocs {

			.homepage-bloc {

				.project-container {

					.project {

						.name {
							min-height: 100px;
						}
					}
				}
			}
		}
	}

	.homepage .homepage-blocs {
		margin: auto auto 0;
	}

	.container-reports .card-report {
		width: 212px;
	}

	#research-bourse-container {
		width: 250px;
	}

	#research-bourse-container #bourse-search {
		width: 100%;
	}

	.bourse-bloc {
		height: 200px;
	}

	.prize-bloc .bloc .bloc-img {
		height: 164px;
	}
}

@media (max-width: 991px) {

	#slvj-window {
		#slvj-back-lightbox {
			width: calc(100% - 30px);
			height: auto;

			.slvj-lightbox {
				padding-top: 56.25%;
				overflow: hidden;
				position: relative;

				iframe {
					height: 100%;
					left: 0;
					position: absolute;
					top: 0;
					width: 100%;
					padding: 7px;
				}
			}
		}
	}

	#bourses-prizes-container .prizes-bourses-list .prizes-of-year .prizes.culture-list {
		grid-template-columns: repeat(3, 1fr);
		display: grid !important;
	}

	#header {
		position: inherit;
		margin-top: -55px;

		#institution {
			display: none;
		}

		&.fixed-for-mobile {
			min-height: 174px;

			.container {
				position: fixed;
				width: 100%;
			}

			.row {
				background-color: white;
			}
		}

		.logo {
			float: left;
			width: auto;
			margin-left: 15px;
		}

		.navigation,
		#mobile-navigation {
			display: none !important;
		}

		.mobile-btn-container {
			position: relative;
			width: 40px;
			height: 18px;
			z-index: 2;
			margin-top: 50px;
			margin-right: 15px;
			float: right;

			.mobile-btn {
				width: 100%;
				background-color: $color-main;
				height: 2px;
				margin: 8px 0;
				transition: .4s all;

				&:before,
				&:after {
					content: '';
					position: absolute;
					top: -15px;
					left: -15px;
					width: 100%;
					height: 2px;
					background-color: $color-main;
					transition: .4s all;
					margin: 15px;
				}

				&:after {
					top: calc(100% - 17px);
				}
			}

			&.open {

				.mobile-btn {
					width: 0;

					&:before {
						transform-origin: right;
						transform: rotate(45deg);
						margin-top: 28px;
						top: calc(-50% + 5px);
						left: -20px;
					}

					&:after {
						transform-origin: right;
						transform: rotate(-45deg);
						top: -20px;
						left: -20px;
					}
				}
			}
		}

		.mobile-nav {
			display: none;
			clear: both;
			position: absolute;
			width: 100%;
			height: calc(100vh - 110px);
			background-color: white;
			margin-left: -15px;
			top: 110px;
			padding-top: 30px;
			overflow: hidden;

			.first-level {
				padding: 21px 15px;
				border-top: 0.5px solid #DAD8D8;
				text-align: left;
				position: relative;

				a {
					font-size: 16px;
					font-weight: bold;
					color: #54585A;
				}

				&:first-child {
					border: none;
				}
			}

			.second-level {
				position: absolute;
				top: 30px;
				right: -100%;
				transition: .4s all;
				width: 100%;
				background-color: white;
				height: 100%;
				z-index: 11;

				&.open {
					right: 0;
				}

				.item {
					padding: 21px 15px;
					margin: 0 15px;
					border-top: 0.5px solid #DAD8D8;
					text-align: left;
					position: relative;

					a {
						font-size: 16px;
						color: #54585A;
					}

					&:first-child {
						border: none;
					}
				}

				.arrow {
					left: 15px;
					right: auto;
					transform: translateY(-50%) rotate(180deg);
				}

				.first-item {
					padding-left: 60px;
					margin: 0;
					border-bottom: 0.5px solid #DAD8D8 !important;

					a {
						font-weight: bold;
					}
				}

				.items {
					overflow: scroll;
					height: calc(100% - 65px);
					padding-bottom: 190px;
				}
			}

			.arrow {
				position: absolute;
				z-index: 10;
				top: 50%;
				right: 15px;
				transform: translateY(-50%);
				height: 30px;
				width: 30px;

				&:before,
				&:after {
					content: '';
					position: absolute;
					width: 20px;
					height: 2px;
					background-color: #54585A;
					right: 5px;
					top: 7px;
					transform: rotate(45deg);
				}

				&:after {
					top: auto;
					bottom: 7px;
					transform: rotate(-45deg);
				}
			}

			.search-form {
				margin: 0 15px;
				height: 30px;
				border-bottom: .5px solid #DAD8D8;
				position: fixed;
				width: calc(100% - 60px);
				bottom: 80px;
				display: none;

				&.visible {
					display: block;
				}

				.search-field {
					float: left;
					width: calc(100% - 50px) !important;
					border: none !important;
				}

				.search-submit {
					float: right;
					position: relative;
					width: 30px;
					z-index: 20;
					background-color: transparent;
					border: none;
				}

				.fa-search {
					position: absolute;
					right: 6px;
					top: -2px;
					font-size: 22px;
				}
			}

			#institution {
				display: block;
				position: fixed;
				bottom: 0;
				top: auto;
				width: 100%;
				left: 0;
				right: auto;
				z-index: 12;
				background-color: white;
				display: none;

				&.visible {
					display: block;
				}

				a {
					float: right;
					padding: 12px 10px;
				}

				.social-medias {
					a {
						padding: 5px 15px;
						color: #54585A;
						font-size: 18px;
					}
				}
			}
		}
	}

	#page-finance {
		.chiffre {
			line-height: 1.2 !important;

			.label {
				display: block;
			}
		}

		.pie-chart-container {
			text-align: center;
			margin-bottom: 30px;
		}

		.tabs-chiffres {
			.tabs-chiffres-header {
				display: inline-block;
				width: 100%;

				.tab-chiffres-header {
					border-radius: 10px;
					border: 1px solid;
					margin-bottom: 10px;
				}
			}

			.rounded-graph {
				display: none !important;
			}

			.tab-chiffres-content {
				border-radius: 20px;
				flex-direction: column;
				height: auto;

				.chiffre {
					margin-bottom: 30px;

					&:first-child {
						margin-top: 30px;
					}

					&.percent {
						.label {
							margin-left: 0;
						}
					}
				}
			}
		}
	}

	.press-slider .slide .card-report {
		h2 a {
			font-size: 14px !important;
		}

		.bottom-ddl {
			bottom: 10px !important;
		}
	}

	#projects-archive .sidebar-bloc {
		margin-top: 40px;
	}

	#search {
		top: 0px;

		form {
			margin-top: 300px;
		}

		.search-form {
			input[type="text"] {
				font-size: 23px;
			}

			input[type="submit"] {
				padding: 18px 15px;
				font-size: 23px;
			}
		}
	}

	#timeline {
		#years {
			display: none;
		}

		#timeline-container {
			.elements-timeline {
				width: 100%;

				.element-position {

					&.right {
						margin-right: 10px;
					}

					&.left {
						margin-left: 10px;
					}
				}
			}
		}

		#line {
			margin-bottom: 50px;
		}
	}

	.template-contact {
		margin-top: 70px;
	}

	.affix {
		width: 100%;
		position: relative !important;
		top: 0 !important;
	}

	.homepage #homepage-actions-container .section-videos .slick-slide:after {
		display: none;
	}

	#footer .menu-footer-contact-container:before {
		left: -8px;
	}

	.prize-bloc .bloc .bloc-img {
		height: 200px;
	}

	.template-contact .container {
		top: inherit;
	}

	.template-contact {
		height: auto;
		margin-bottom: 60px;
	}

	.container-organisation .membre-card.big {
		height: 275px;
	}

	#header #institution {
		right: 0;
	}

	#header {
		.logo-container {
			text-align: center;
			margin-top: 60px;

			.white {
				display: none;
			}

			.color img {
				display: block;
				opacity: 1;
			}

		}

		.navigation {
			margin-top: 50px;
			margin: 10px 0 12px 0;
			display: inline-block;
			width: 100%;

			.menu-content {
				float: none !important;
				display: block;
				text-align: center;

				#header-navigation {
					float: none !important;
					display: inline-block !important;

					.menu-item-has-children:not(.active) a {
						color: #575756;
					}
				}
			}
		}
	}

	.homepage .slide .banner h2 {
		font-size: 22px;
	}

	.homepage .homepage-actions .homepage-action-left .description,
	.homepage .homepage-actions .homepage-action-right .description {
		height: auto !important;
		padding-bottom: 0;
	}

	.homepage .homepage-actions .homepage-action-left .link a,
	.homepage .homepage-actions .homepage-action-right .link a {
		margin-top: 0;
		margin-bottom: 20px;
		display: inline-block;
		float: right;
	}

	#footer {
		.col-sm-2.footer-bloc {
			width: 33.333%;
			height: 200px;
		}
	}

	.homepage .homepage-actions .homepage-action-left #bottom-vid-part-left {
		width: 750px;
		margin: 0 auto;
		float: none;
		padding: 15px;
	}

	.homepage .homepage-actions .homepage-action-right #bottom-vid-part-right {
		width: 750px;
		margin: 0 auto;
		float: none;
		padding: 15px;
	}

	.homepage .homepage-actions .homepage-action-left .description,
	.homepage .homepage-actions .homepage-action-right .description {
		padding: 0;
	}

	.homepage .homepage-actions .homepage-action-left .link a,
	.homepage .homepage-actions .homepage-action-right .link a {
		float: none;
		margin: 0 0 0 0;
	}

	.homepage-actions .link {
		text-align: right;
		padding: 0;
	}

	.homepage .homepage-presentation .presentation-slogan {
		padding: 0 15px;
		margin-bottom: 10px;
	}

	.homepage .homepage-presentation .presentation-content {
		padding: 0 15px;
	}

	.container-organisation .membre-card.small {
		height: 350px;
	}

	.container-organisation .bloc-organisation.left {
		padding-right: 15px;
	}

	.container-organisation .bloc-organisation.right {
		padding-left: 15px;
	}

	.container-reports .card-report {
		width: 100%;
	}

	#research-bourse-container {
		position: absolute;
		top: 30px;
		right: 15px;
	}

	#bourses-lists {
		.years-elements-row {
			padding-left: calc(100% - 600px);
		}
	}

	#years {
		&.fixed {
			top: 220px !important;
		}
	}

	.bourse-bloc {
		display: inline-block;
		width: 166px;
		height: 245px;
	}

	.years_horizontal_listing {
		max-width: inherit;
		width: 100%;
	}
}

@media (max-width: 800px) {
	.template-rendez-vous {
		.arrows {
			.prev {
				left: 0;
				z-index: 99;
			}

			.next {
				right: 0;
				z-index: 99;
			}

			img {
				height: 25px;
			}
		}

		.slider-rendez-vous {
			.slide {
				padding: 0px 50px;

				.see-more {
					right: 53px;
				}
			}
		}
	}

	.press-slider-next {
		right: 5px;
	}

	.press-slider-prev {
		left: -10px;
	}

	.press-slider {
		margin: 0 15px 0 20px;
	}
}


@media (max-width: 767px) {

	#banner {
		height: 55vh !important;
	}

	#intro {
		margin-top: 0;
	}

	#intro .content {
		padding-top: 0;
	}

	.homepage .slide .banner .details a {
		display: none;
		visibility: hidden;
	}

	#bourses-prizes-container .prizes-bourses-list .prizes-of-year .prizes.culture-list {
		grid-template-columns: repeat(2, 1fr);
	}



	.copyright-video {
		transform: translate(0px, calc(-100% - 3px));
	}

	#projects-archive .projects-grid,
	.single-project .related-posts-container .blog-posts-grid,
	.blog .blog-posts-grid,
	.page-template-template-foundation .template-foundation-content .values,
	.page-template-template-press .press-posts-grid {
		grid-template-columns: repeat(1, 1fr);
	}

	#projects-archive .filters {
		display: none;
	}

	.template-press,
	.blog {
		.filters {
			display: none;
		}
	}

	.timeline-content-container {
		flex-direction: column;

		.content {
			padding-left: 0 !important;
		}
	}

	.single-rapport_annuel {

		#header .logo-container {
			height: 160px !important;
			padding-top: 50px !important;
		}

		#mobile-navigation {
			.slicknav_nav a {
				margin: 5px !important;
			}
		}

		#regards #regards-sommaire {
			margin-top: 40px !important;
		}

		.section {
			margin-top: 30px !important;
			// max-height: 2250px !important;
			&#regards {
				padding-top: 0 !important;
			}
		}

		.sommaire-element {
			h3 {
				margin-top: 10px !important;
			}

			.text {
				margin-top: 10px !important;

				p {
					min-height: auto !important;
					margin-bottom: 10px !important;
				}

				.small {
					margin-bottom: 20px;
				}
			}
		}

		.section-intro {
			margin-top: 30px !important;

			.sub-title {
				margin-bottom: 20px !important;
			}
		}

		.intro-left .intro-bloc {
			padding: 25px !important;

			.text {
				margin-top: 15px !important;

				p {
					margin-bottom: 10px !important;
				}
			}

			.share {
				margin-top: 20px !important;

				.social-share {
					margin-top: 10px !important;
				}
			}
		}

		.builder-element {
			&.citation {
				margin-top: 30px !important;

				.citation-text {
					font-size: 22px !important;
				}
			}

			&.texte,
			&.video {
				margin-top: 30px !important;
			}
		}

		.bio .builder-element-content {
			padding: 25px 0 !important;
		}

		.orgue-point {
			padding: 20px 0 !important;
		}

		.intro-personnes {
			margin-bottom: 0 !important;
		}

	}

	#timeline {
		#today {
			margin-top: 500px;
		}
	}

	body,
	.single-rendez-vous {
		padding-top: 0 !important;
	}

	.block-scroll {
		overflow: hidden;
	}

	#footer .footer-newsletter {
		border-left: 0;
	}

	#search #search-container {
		padding: 0 20px;
	}

	#footer .menu-footer-contact-container:before {
		display: none;
	}

	.prize-bloc .bloc {
		height: auto;
	}

	.prize-bloc .bloc .bloc-img {
		height: auto;
	}

	#menu-menu-principal {
		display: none;
	}

	.sections-selector {
		padding-top: 50px !important;
		background: rgba(0, 0, 0, 0.9) !important;
	}

	#banner h1,
	#banner h2 {
		font-size: 30px !important;
	}

	.slicknav_nav .slicknav_row:hover {
		background: none !important;
		color: white !important;
	}

	.slicknav_nav .slicknav_item a {
		font-size: 15px !important;
	}

	.slicknav_nav a:hover {
		background: none !important;
		color: white !important;
	}

	.slicknav_menu {
		display: block;
		background-color: #575756;

		.caret {
			display: none;
		}

		.dropdown-menu {
			float: none !important;
			position: inherit !important;
			top: inherit !important;
			background: none !important;

			li {
				a {
					font-size: 14px !important;
					padding-bottom: 10px !important;
					padding-top: 10px !important;
				}
			}
		}
	}

	#header {
		.search {
			position: absolute;
			color: $color-main;
			bottom: 0;
			left: 15px;
			margin: 0;

			i {
				&:hover {
					color: white;
					opacity: 0.8;
				}
			}
		}
	}

	#main-research-container {
		position: absolute !important;
		bottom: -36px !important;
		left: 40px !important;

		#main-research-submit {
			background-image: url('../../images/glass.png');
		}
	}

	.homepage .slide .banner h2 {
		font-size: 18px;
		line-height: 25px;
	}

	.project-supported .project-thumbnail ul li a {
		padding: 4px 15px;
		font-size: 14px;
	}

	.project-supported h3 a {
		font-size: 24px;
	}

	#projects .slider-navigation {
		text-align: left;
		position: inherit;
		top: inherit;
		left: inherit;
		transform: inherit;
		margin: 0 0 20px 0;
	}

	#footer {
		.footer-upper {
			margin-bottom: 0;
		}

		.col-sm-2.footer-bloc {
			width: auto;
			height: auto;
		}

		.footer-bloc {
			margin-bottom: 25px;
		}

		.footer-newsletter {
			padding: 0;
			padding-left: 15px;
			padding-right: 15px;
		}

		.gform_wrapper .gform_footer {
			padding-bottom: 0;
		}

		#footer .footer-socials {
			float: left !important;
		}
	}

	.homepage .homepage-actions .homepage-action-left #bottom-vid-part-left {
		width: 100%;
		padding: 15px 0 15px 0;
	}

	.homepage .homepage-actions .homepage-action-right #bottom-vid-part-right {
		width: 100%;
		padding: 15px 0 15px 0;
	}

	.homepage .slide .banner .informations {
		width: 100%;
		border-right: 0;
		padding-right: 0;
		margin-bottom: 25px;
	}

	.homepage .slide .banner .details-container {
		display: block;
		width: 100%;
		position: inherit;
		height: auto;
		padding-left: 0;
	}

	.homepage .slide .banner .details {
		position: inherit;
		top: inherit;
		transform: inherit;
	}

	.homepage .slide .banner {
		padding: 20px;
		bottom: 50%;
		transform: translateY(50%);
	}

	#laureat-table-footer a {
		padding-right: 0;
	}

	.section-title {
		margin-top: 40px;
	}

	.homepage .homepage-actions .homepage-action-left .link a,
	.homepage .homepage-actions .homepage-action-right .link a {
		padding: 10px 10px;
	}

	.homepage .homepage-actions .homepage-action-left .description,
	.homepage .homepage-actions .homepage-action-right .description {
		padding: 0 10px;
	}

	.homepage-news-background .homepage-news-bloc {
		padding: 20px;
		font-size: 30px;
		line-height: 28px;

	}

	#search form {
		width: 100%;

		input {
			width: 70%;
			padding: 10px 12px;
			font-size: 12px;
		}

		input[type=submit] {
			width: auto;
			padding: 8px 0;
			font-size: 12px;
			width: 30%;
		}
	}

	#laureat-table-footer a {
		display: block;
		border-right: 0;
	}

	.container-organisation .membre-card.big {
		height: 290px;
	}

	.container-organisation .membre-card.small {
		height: 350px;
	}

	.container-organisation .membre-card h3 {
		margin: 20px 0 5px;
	}

	.container-reports .card-report {
		width: 250px;
	}

	.years_horizontal_listing li.year a {
		padding: 5px 16px;
	}

	#research-bourse-container {
		position: initial;
		top: inherit;
		right: inherit;
		width: 100%;
		margin-bottom: 40px;
	}

	#bourses-lists {
		.years-elements-row {
			padding-left: calc(100% - 400px);
		}
	}

	#research-bourse-container #research-bourse-submit {
		right: 15px;
		top: 0px !important;
	}

	#bourses-lists .years-elements-row .homepage-news-more {
		margin-top: 40px;
		margin-bottom: 0;
	}

	#laureat .entry #research-laureat-container #research-laureat {
		width: 100%;
	}

	#banner #section-title-container {
		left: 15px !important;
		top: 25% !important;
		transform: none !important;
	}

	.homepage-news-background {
		height: 290px;
	}

	.homepage-news-bloc {
		margin-top: 0px !important;
		width: calc(100% - 60px) !important;
		padding-right: 15px !important;
		padding-left: 15px !important;
	}

	.home-news-short .cat {
		z-index: -1;

		.category {
			.cat {
				z-index: 1;
			}
		}
	}

	.homepage-news-bloc-short {
		margin-top: -120px !important;
		width: calc(100% - 60px) !important;
		padding-right: 15px !important;
		padding-left: 15px !important;
		margin: 0 auto;
		background-color: white;
		padding-top: 40px;
	}

	.homepage-news {
		.arrows {
			.prev {
				width: 25px;
				height: 25px;
				position: absolute;
				border-left: 2px solid #555759;
				border-top: 2px solid #555759;
				z-index: 2;
				transform: rotate(-45deg);
				top: 500px;
			}

			.next {
				width: 25px;
				height: 25px;
				position: absolute;
				border-left: 2px solid #555759;
				border-top: 2px solid #555759;
				z-index: 2;
				transform: rotate(135deg);
				right: 15px;
				top: 500px;
			}
		}
	}

	.vimeo-link {
		right: 25px;
	}
}

@media (max-width: 640px) {

	#timeline {

		#line {
			left: 35px;
		}

		#line-fill {
			left: 35px;
		}

		#timeline-container {

			.elements-timeline .element-position {
				float: left !important;
				margin-left: 110px !important;
				max-width: 75% !important;
				width: 100% !important;

				.year {
					right: initial !important;
					left:  10px !important;
				}

				.bar {
					float: right !important;
					left: 21px !important;
				}
			}
		}
	}

	.fix-align-mobile {
		clear: both;
		padding-top: 10px;
	}

	.template-rendez-vous .intro {
		font-size: 18px;
	}

	#bourses-prizes-container .prizes-bourses-list .prizes-of-year .prizes.culture-list {
		grid-template-columns: 1fr;
	}

	#header .logo {
		margin-left: 0;
		width: 120px;
	}

	#header .mobile-btn-container {
		width: 30px;
		margin-top: 40px;
	}

	#header .mobile-btn-container.open .mobile-btn:before {
		margin-top: 22px;
	}

	#header.fixed-for-mobile {
		min-height: auto;
	}

	#header .mobile-nav {
		top: 99px;
		height: calc(100vh - 99px);
	}

	#header .mobile-nav .first-level {
		padding: 15px;
	}

	#header .mobile-nav .first-level a {
		font-size: 14px;
	}

	.mobile-nav {
		#institution {
			.social-medias {
				width: 100%;
				display: block;

				li {
					display: inline-block;
					float: none;
				}
			}

			.contact,
			.submit-project {
				width: 50%;
			}
		}
	}

	#bourses-prizes-container {
		.filters {
			display: flex;
			flex-wrap: wrap;

			.filter {
				margin-bottom: 10px;
			}
		}
	}

	#section-title-container {
		left: 15px !important;
		transform: none !important;
	}

	.page-template-template-age,
	.page-template-template-culture,
	.page-template-template-scientifique {
		#intro .content {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.soutien-blocs {
		padding: 0 !important;
	}

	#bourses-prizes-container {
		.search-container {
			.search {
				width: calc(100% - 42px) !important;
			}
		}

		.prizes,
		.bourses {
			display: block !important;

			.prize-card,
			.bourse-card {
				margin-bottom: 40px;
			}

			.bourse-card,
			.prize-card-culture {
				.image {
					height: 400px;
				}

				.details {
					height: calc(100% - 400px);
				}
			}
		}
	}

	#projects-container .header-area {
		flex-direction: column;

		.button {
			margin-bottom: 15px;
		}
	}

	.videos-section-header {
		flex-direction: column;
		align-items: baseline;

		a {
			margin-bottom: 15px;
		}
	}

	#bourses-lists {

		#years {
			&.fixed {
				top: 80px !important;
			}
		}

		.years-elements-row {
			padding-left: calc(100% - 200px);
		}

	}

	.template-contact #field_1_5 {
		width: 100% !important;
	}

	.template-contact #field_1_4 {
		width: 100% !important;
	}

	.homepage-news-bloc {
		width: 100%;
		padding: 40px 0;
		margin-bottom: 40px;
	}

	.homepage #slider .slick-arrow.slick-prev {
		left: 7px !important;
	}

	.homepage #slider .slick-arrow.slick-next {
		right: 7px !important;
	}

	.section-videos .slick-dots li {
		margin: 5px 0;
	}

	#header .submit-project {
		padding: 8px 10px;
	}

	.container-organisation .membre-card.big {
		height: 240px;
	}

	.container-organisation .membre-card.small {
		height: 275px;
	}

	.searchform-search-page form input {
		width: 175px;
	}

	.searchform-search-page form input[type=submit] {
		width: 112px;
		padding: 15px 9px;
	}

	.projects-btn,
	.all-news {
		float: none !important;
		display: inline-block !important;
		margin-top: 0 !important;
		margin-bottom: 10px !important;
	}
}

@media (max-width: 500px) {

	.template-events .year-group .year {
		width: 100%;
		margin-bottom: 8px;
	}

	.template-events .year-group .more {
		margin-bottom: 22px;
	}

	.year-filter {
		float: none !important;
		margin-bottom: -50px;
	}

	.single-presse {
		.back-and-links {
			flex-direction: column;
			align-items: flex-start;

			.links {
				margin-top: 15px;

				.link {
					margin-left: 0;
					margin-right: 25px;
				}
			}
		}
	}
}

@media (max-width: 352px) {
	#bourses-prizes-container {
		.years-container {
			.years-list {
				width: 235px;
			}
		}
	}

	.mobile-nav {
		.search-form {
			bottom: 100px !important;
		}
	}
}


@media (max-width: 321px) {
	#search {
		top: 0px;

		.search-form {
			input[type="text"] {
				font-size: 19px;
			}

			input[type="submit"] {
				padding: 20px 16px;
				font-size: 19px;
			}
		}
	}
}
