// Importing Bootstrap

@import "_google_fonts";
@import "_font-awesome";
@import "_bootstrap";
@import "variables";
@import "slicknav";
@import "animate";
@import "global";
@import "header";
@import "footer";
@import "banner";
@import "template-page";
@import "template-reports";
@import "template-organisation";
@import "template-contact";
@import "form";
@import "template-criterion";
@import "template-interdomaines";
@import "template-rapports-annuel";
@import "template-timeline";
@import "single-bourse";
@import "content";
@import "search";
@import "blog";
@import "sections";
@import "responsive";

/* from custom.css */
.comment-form .submit{
	background-color: #575756;
    padding: 8px 15px;
    border: none;
    color: white;
    font-weight: bold;
}

.comment-form form{
	display: flex;
    flex-direction: column;
}

.comment-form  .comment-form-comment{
	order: 3;
}
.comment-form  .comment-form-author{
	order: 1;
}
.comment-form  .form-submit{
	order: 4;
}
.comment-form  .comment-form-email{
	order: 2;
}

.comment-form  .comment-form-email input{
	width: 100% !important;
    border: 1px solid #DEDEDE !important;
    background: #fff !important;
}

.section-videos .section-video {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
}

body {
    word-break: break-word !important;
}

.comment-form .error{
    border-color: #f11212 !important;
}

.comment-answers{
    width: calc(100% - 20px);
    margin-left: 20px;
}

.comment-answers hr{
    margin-bottom: 10px;
    margin-top: 10px;
} 