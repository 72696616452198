#banner{
  height: 610px;
  max-width: 1900px;
  width: 100%;
  margin: 0 auto 40px auto;
  padding-top: 100px;
  background-position: center !important;
  position: relative;
  z-index: -1;
  overflow: hidden;
  &.big {
	  height: 800px;
  }

  .banner-video{
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }

  .banner-overlay{
  	position: absolute;
  	top: 0;
  	left: 0;
  	width: 100%;
  	height: 100%;
  }

	#section-title-container {
		display: inline-block;
		position: absolute;
		top: 250px;
		.subtitle {
			color: white;
		}
	}

  .title, h1, h2{
    display: inline-block;
    font-size: 38px;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    margin: 0;
  }


}

.section-corner{
	position: relative;

    &:before{
      content: '';
      display: block;
      background: blue;
      top: -6px;
      left: -5px;
      position: absolute;
      width: 82px;
      height: 46px;
      background: url('../../images/border-small-black.png') top left no-repeat;
    }

    &:after{
      content: '';
      display: block;
      bottom: -5px;
      right: -5px;
      position: absolute;
      width: 82px;
      height: 46px;
      background: url('../../images/border-small-black.png') bottom right no-repeat;
    }

    &.culture{
	    &:before{
      	background: url('../../images/border-small-blue.png') top left no-repeat;
	    }
	    &:after{
	      background: url('../../images/border-small-blue.png') bottom right no-repeat;
	    }
    }

    &.societe{
	    &:before{
      	background: url('../../images/border-small-yellow.png') top left no-repeat;
	    }
	    &:after{
	      background: url('../../images/border-small-yellow.png') bottom right no-repeat;
	    }
    }

    &.ages-societe{
	    &:before{
      	background: url('../../images/border-small-yellow.png') top left no-repeat;
	    }
	    &:after{
	      background: url('../../images/border-small-yellow.png') bottom right no-repeat;
	    }
    }
    &.age-et-societe{
	    &:before{
      	background: url('../../images/border-small-yellow.png') top left no-repeat;
	    }
	    &:after{
	      background: url('../../images/border-small-yellow.png') bottom right no-repeat;
	    }
    }

    &.sciences-sante{
	    &:before{
      	background: url('../../images/border-small-red.png') top left no-repeat;
	    }
	    &:after{
	      background: url('../../images/border-small-red.png') bottom right no-repeat;
	    }
    }

}
