.section-title{
  font-size: 28px;
  color: #555759;
  margin-bottom: 25px;
}

.project-supported{
  font-size: 15px;
  line-height: 1.7;

  p {
	  font-weight: normal;
  }

  .project-thumbnail{
    position: relative;
    background-color: black;
    overflow: hidden;

    img {
	    transform: scale(1.0);
	    transition: all 0.4s ease-in-out;
	    &:hover {
		    transform: scale(1.03);
	    }
    }

    ul{
      position: absolute;
      left: 40px;
      margin: 0;
      padding: 0;
      bottom: 0;

      li{
        float: left;
        display: inline;

        a{
          display: block;
          padding: 5px 14px;
          font-size: 16px;
          font-weight: bold;
          text-transform: uppercase;
          color: white;
          background: #383838;

          &:hover{
            text-decoration: none;
          }

		&.culture{
			background: $color-culture;
			&:hover {
				background-color: lighten($color-culture, 5%);
			}
		}
		&.ages-societe{
			background: $color-societe;
			&:hover {
				background-color: lighten($color-societe, 5%);
			}
		}
		&.sciences-sante{
			background: $color-scientifique;
			&:hover {
				background-color: lighten($color-scientifique, 5%);
			}
		}

        }
      }
    }
  }

  h3, h3 a{
    font-weight: bold;
    font-size: 21px;
    color: #464849;
    text-decoration: none;
    margin-bottom: 20px;

    &:hover{
      text-decoration: underline;
      color: darken(#464849, 10%);
    }
  }
}

.videos-section-header{
	margin-bottom: 25px;
	display: flex;
    align-items: center;
    justify-content: space-between;
	h3{
		color: #575756;
		font-size: 28px;
	}
	a{
		color: #575756;
		text-decoration: none;
		font-size: 18px;
		margin-top: 20px;
		img{
			width: 28px;
			height: auto;
			margin-left: 15px;
		}
	}
}

.section-videos{
  position: relative;
  display: block;
  margin: auto;
  margin-bottom: 0 !important;
  background-color: black;

	.slick-dots {
		width: 16px;
		height: auto;
		right: 15px;
		top: 50%;
		bottom: inherit;
		transform: translateY(-50%);
		li {
			display: block;
			margin: 0;
			width: 16px;
			margin: 12px 0px;
			&.slick-active {
				button {
					&:before {
						background: url('../../images/dot_full.png') no-repeat;
						opacity: 1;
					}
				}
			}
			button {
				padding: 0;
				width: 16px;
				height: 16px;
				font-size: inherit;
				margin: 0;
				&:before {
					width: 16px;
					height: 16px;
					content: "";
					background: url('../../images/dot.png') no-repeat;
					background-size: 100%;
					padding: 0;
					opacity: 1;
				}
			}
		}
	}

  .section-video{
	position: relative;
    width: 33.3%;
    float: left;
    display: inline;
    text-align: center;
    color: white;
    max-height: 400px;
		overflow: hidden;

	&.ages-societe {
		border-bottom: 2px solid $color-societe;
	}

	&.culture {
		border-bottom: 2px solid $color-culture;
	}

	&.sciences-sante {
		border-bottom: 2px solid $color-scientifique;
	}

	&:hover {
		img {
			transform: scale(1.03);
	    }
	}

    img {
	    width: 100%;
	    height: auto;
	    transform: scale(1.0);
	    transition: all 0.4s ease-in-out;
	    &:hover {
		    transform: scale(1.03);
	    }
    }
    .video-infos {
			
	    .content {
			position: relative;
			top: 50%;
			transform: translateY(-50%);
	    }
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
			background-color: rgba(0, 0, 0, 0.4);
			transition: all 0.4s linear;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			background-blend-mode: overlay;
	    &:hover{
			background-color: rgba(0, 0, 0, 0.5);
			transform: scale(1.02);
	    }
    }
  }

  .section-video-double{
	  width: 50%;
  }

  .section-video-category{
    font-size: 18px;
    color: #FFFFFF;
    text-transform: uppercase;
    border-bottom: 2px solid;
    width: auto;
    display: inline-block;
    margin-bottom: 10px;

    &.culture{ border-color: $color-culture; }
    &.ages-societe{ border-color: $color-societe; }
    &.sciences-sante{ border-color: $color-scientifique; }
    &.interdomaines{ border-color: $color-interdomaines; }
  }

  .section-video-text{
    font-weight: bold;
    font-size: 14px;
    color: #FFFFFF;
    margin-top: 15px;
    margin-bottom: 15px;
    line-height: 20px;
    padding: 0 50px;
  }

  .section-play{
    display: block;
    width: 68px;
    height: 68px;
    background: url(../../images/video-play.png) no-repeat;
    margin: auto;
  }

  .homepage{
	  .video-infos{
		  padding-top: 60px !important;
	  }
		.section-video-text{
			margin-bottom: 20px !important;
		}
  }

}

/**
* Highlight sidebar in section pages
*/
.highlight{
  margin-bottom: 40px;
  padding-top: 60px;
  padding-bottom: 10px;

  .highlight-content{
    padding: 10px;
    font-size: 14px;
    color: #575756;
    line-height: 28px;

    h3{
      font-size: 24px;
      color: #464849;
      margin: 0 0 20px 0;
      padding: 0;
    }

    .button{
      font-size: 15px;
      font-weight: bold;
      text-transform: uppercase;
      display: block;
      width: 100%;
      text-align: center;
      padding: 20px 20px;
      margin-top: 40px;
      &.disabled {
          background-color: #b7b7b7 !important;
          cursor: not-allowed;
      }
      &:hover{
        text-decoration: none;
      }
    }
    form{
    margin-bottom: 40px;
    overflow: hidden;

    label{ display: none !important; }

    ul, li{
      margin: 0 !important;
      padding: 0 !important;
      list-style: none !important;
    }

    .ginput_container{ margin: 0px !important; }

    .gform_body, .gform_footer{
      clear: none !important;
      float: left !important;
      display: inline !important;
      margin: 0 !important;
      padding: 0 !important;
    }

    .gform_body{ width: 80% !important; }
    .gform_footer{ width: 20% !important; }

    input[type="email"], input[type="text"], input[type="submit"]{
      display: block !important;
      width: 100% !important;
      height: 45px !important;
      background: #DEDEDE !important;
      border: 0px !important;
      margin: 0 !important;
      padding: 0 !important;
    }

    input[type="text"]{
      padding-left: 20px !important;
      padding-right: 20px !important;
    }

    input[type="submit"]{
      background: #383838 !important;
      color: #fff !important;
      font-weight: bold !important;

      &:hover{ background: #444 !important; }
    }
  }
  }

  // Scientifique
  &.highlight-scientifique{
    background: rgba(231, 64, 17, 0.05);

    .button{
      background: $color-scientifique;
      color: white;

      &:hover{
        background: darken($color-scientifique, 2%);
      }
    }
  }

  // Age et société
  &.highlight-societe{
    background: rgba(255, 204, 0, 0.05);

    .button{
      background: $color-societe;
      color: white;

      &:hover{
        background: darken($color-societe, 2%);
      }
    }
  }

  // Culture
  &.highlight-culture{
    background: rgba(0, 86, 157, 0.05);

    .button{
      background: $color-culture;
      color: white;

      &:hover{
        background: darken($color-culture, 2%);
      }
    }
  }

  // Culture
  &.highlight-interdomaines{
    background: rgba(87, 86, 86, 0.05);

    .button{
      background: $color-interdomaines;
      color: white;

      &:hover{
        background: lighten($color-interdomaines, 2%);
      }
    }
  }

  /**
  * Newsletter box
  */
  .newsletter{

    .gform_body,
    .gform_footer{
      float: left !important;
      display: inline !important;
      clear: none !important;
      margin: 0 !important;
      padding: 0 !important;
    }

    .gform_body{
      width: 70% !important;
    }

    .gform_footer{
      width: 30% !important;
    }

    ul{
      margin: 0 !important;
      padding: 0 !important;
      list-style: none;

      li{
        margin: 0 !important;
        padding: 0 !important;
      }
    }

    label{
      display: none !important;
    }
  }

}

/**
* Bourses
*/

.loaderIS {
	&.showed {
		display: block;
	}

	&.not-showed {
		display: none;
	}

	margin-top: 40px;
	text-align: center;

	.spinner {
		animation: rotate 2s linear infinite;
		z-index: 2;
		top: 50%;
		left: 50%;
		margin: -25px 0 0 -25px;
		width: 50px;
		height: 50px;

		& .path {
		  stroke: hsl(210, 70, 75);
		  stroke-linecap: round;
		  animation: dash 1.5s ease-in-out infinite;
		}

	  }

	  @keyframes rotate {
		100% {
		  transform: rotate(360deg);
		}
	  }

	  @keyframes dash {
		0% {
		  stroke-dasharray: 1, 150;
		  stroke-dashoffset: 0;
		}
		50% {
		  stroke-dasharray: 90, 150;
		  stroke-dashoffset: -35;
		}
		100% {
		  stroke-dasharray: 90, 150;
		  stroke-dashoffset: -124;
		}
	  }

}

#show-me-more {
	color: #A3A3A3;
	font-size: 20px;
	text-align: center;
	margin-top: 20px;
	display: none;

	button {
		border: 2px solid #A3A3A3;
		padding: 5px 20px;
		background: none;
	}
}

.page-id-309 .years-elements-row .bourse-bloc .bourse-hover-content{
	bottom: 22px ! important;
}

#bourses-lists {
	background-color: #F5F5F5;
	padding-bottom: 0px;
	min-height: 450px;

	#bourse-search-result {
		margin-bottom: 40px;
		padding-left: calc(100% - 960px);

	}
	#research-result-title {
		padding-left: calc(100% - 960px);
		display: none;
		&:after {
			margin-top: 0px!important;
		}
	}

	h2 {
		margin-top: 50px;
	}

	.years-elements-row {
		padding-left: calc(100% - 960px);
	}

	#years {
		position: absolute;
		top: 70px;
		overflow-y: scroll;
		max-height: 592px;

		&.fixed {
			position: fixed!important;
			top: 140px!important;
			max-height: 592px;
		}

		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			li {
				margin-bottom: 30px;
				margin-right: 30px;
				a {
					color: #A3A3A3;
					font-size: 28px;
					&:hover {
						color: #464849;
						text-decoration: none;
					}
				}
				&.active {
					a {
						color: #464849;
					}
				}
				&.more {
					text-align: center;
					position: relative;
					top: 10px;
					a {
						font-size: 14px;
						color: #333333;
					}
				}
			}
		}
	}

	#laureat-content {

		.section-title {
			margin-top: 70px;
		}

		.laureat-year-content {
			display: none;
			&.first {
				display: block;
			}
		}

		#laureat-prizes {
			.prizes-group-title {
				font-weight: normal;
				color: #555759;
				font-size: 28px;
				margin-top: 55px;
			}
            .laureats-group-description {
                margin: 0 0 35px;
                font-size: 16px;
            }
		}

		#laureat-bourses {
			margin-top: 35px;
			display: inline-block;
			width: 100%;

			.bourse-group-title {
				margin-top: 0;
			}

            .laureats-group-description {
                margin: 0 0 35px;
                font-size: 16px;
            }
		}
	}
	.bloc-search-result {
		margin-bottom: 40px;
	}

	.bourse-bloc {

		.image-container {
			transition: transform 250ms linear;
		}

		&:hover {

			.image-container {
				transform: scale(1.05);
			}
		}

		&:hover {
			.bourse-hover-container {
				opacity: 1;
				height: 100%;

				.boursier-taxonomy {
					display: none!important;
					opacity: 0!important;
				}
			}

			.bourse-hover-content {

				h4 {
					&:after {
						height: 4px!important;
						margin-top: 13px!important;
						margin-bottom: 13px!important;
					}
				}
			}

			.boursier-fonction {
				position: relative!important;
				top: initial!important;
				opacity: 1!important;
				display: block!important;
			}

		}

		.bourse-hover-container {
			position: absolute;
			opacity: 1;
			bottom: 0!important;
			top: auto!important;
			width: 100%;
			max-width: 166px;
			height: 25%;
			/* background-color: rgba(0, 0, 0, 0.5); */
			background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 90%, rgba(0,0,0,1) 100%);
			text-align: center;
			transition: all 0.2s linear;

			.boursier-taxonomy {
				display: block;
				opacity: 1;
				transition: all .2s linear;
				font-weight: bold;
				color: white;
				position: relative;
				bottom: 0px;
				top: 50%;

				.boursier-term {
					margin-bottom: 0px;
				}
			}

			.bourse-hover-content {
				padding: 0px!important;

				h4 {
					&:after {
						height: 2px;
						margin-top: 5px;
						transition: all .2s linear;
					}
				}

				.boursier-fonction {
					opacity: 0;
					position: relative;
					top: 15px;
					transition: all 1ms linear;
					display: none;
				}

			}

		}
	}


}




/**
* Laureats
*/
#laureat {
	background-color: #F5F5F5;
	padding-bottom: 60px;
    min-height: 450px;
	.container {
		position: relative;
	}
	.entry {
		background-color: #575756;
		padding: 30px 0;
		.title {
			color: #FFFFFF;
			font-size: 28px;
			font-weight: normal;
			margin: 0;
		}

		#research-laureat-container {
			text-align: right;
			position: relative;
			top: 20px;
			#research-laureat {
				width: 405px;
				background: none !important;
				border: 0;
				border-bottom: 1px solid rgba(255, 255, 255, 0.9);
				font-size: 14px;
				color: #fff;
			}
			#research-laureat-submit {
				position: absolute;
				top: 0;
				right: 0;
				border: 0;
				background: none;
				background-image: url('../../images/glass.png');
				width: 13px;
				height: 13px;
                display: block;
                background-repeat: no-repeat;
			}
		}
	}

	#years {
		position: absolute;
		top: 70px;
		left: -105px;
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			li {
				margin-bottom: 30px;
				a {
					color: #A3A3A3;
					font-size: 28px;
					&:hover {
						color: #464849;
						text-decoration: none;
					}
				}
				&.active {
					a {
						color: #464849;
					}
				}
				&.more {
					text-align: center;
					a {
						font-size: 14px;
						color: #333333;
					}
				}
			}
		}
	}

	#laureat-content {
		#laureat-search-result {

		}

		.section-title {
			margin-top: 70px;
		}

		.laureat-year-content {
			display: none;
			&.first {
				display: block;
			}
		}

		#laureat-prizes {
			.prizes-group-title {
				font-weight: normal;
				color: #555759;
				font-size: 28px;
				margin-top: 55px;
			}
            .laureats-group-description {
                margin: 0 0 35px;
                font-size: 16px;
            }
		}

		#laureat-bourses {
			margin-top: 35px;
			display: inline-block;
			width: 100%;

			.bourse-group-title {
				margin-top: 0;
			}

            .laureats-group-description {
                margin: 0 0 35px;
                font-size: 16px;
            }
		}
	}
	.bloc-search-result {
		margin-bottom: 40px;
	}

}

#intro {
	margin-top: -370px;
	background-color: white;
	margin-bottom: 100px;
	.content {
		padding: 40px 30px;
	}
}
.page-template-template-interdomaines-main {
    #intro {
        margin-bottom: 0;
    }
}

#projects-archive {
	.project-bloc {
		margin-bottom: 70px;
		&:last-child {
			margin-bottom: 0;
		}
		.project-thumbnail {
			position: relative;
			overflow: hidden;
			a {
				display: block;
			}
			img {
				transition: all 0.6s ease-in-out;
				transform: scale(1.00);
				&:hover {
					transform: scale(1.03);
				}
			}
		}
		.project-title {
			margin: 20px 0 15px 0px;
			a {
				color: #464849;
				font-size: 25px;
				font-weight: bold;
			}
		}
		.project-excerpt {
			font-size: 15px;
			line-height: 28px;
			color: #575756;
		}
	}
}

.title-intern-section{
	font-weight: bold;
	font-size: 18px;
	text-transform: uppercase;
	position: relative;
	padding-left: 50px;
	margin-bottom: 20px;

	&:before{
		position: absolute;
		width: 35px;
		height: 15px;
		border-bottom: 4px solid black;
		content: '';
		left: 0px;
	}

	&.culture{
		color: $color-culture;

		&:before{
			border-color: $color-culture;
		}
	}

	&.societe{
		color: $color-societe;

		&:before{
			border-color: $color-societe;
		}
	}

	&.sciences-sante{
		color: $color-scientifique;

		&:before{
			border-color: $color-scientifique;
		}
	}
}
#laureats-elements {
	.laureat-table-element {
		display: none;
	}
}
#laureats-table {
	.laureat-table-element {
		width: 100%;
		display: inline-block;
		position: relative;
		overflow: hidden;
		width: 25%;
		height: 180px;
		float: left;
		&:nth-child(3n+0) {
			height: 360px;
			margin-top: -180px;
		}
		&:nth-child(2) {
			clear: left;
		}
		&:nth-child(4) {
			margin-top: -180px;
		}
		&:hover {
			.element-hover-container {
				opacity: 1;
			}
			.image-container {
				transform: scale(1.03);
			}
		}
		.image-container {
			width: 100%;
		    height: 100%;
		    background-size: cover;
		    background-position: center;
		    background-repeat: no-repeat;
		    transform: scale(1.0);
		    transition: all 0.6s ease-in-out;
		}

		img {
			width: 100%;
		}

		.element-hover-container {
			opacity: 0;
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.5);
			text-align: center;
			-webkit-transition: opacity 0.2s linear;
			-moz-transition: opacity 0.2s linear;
			-ms-transition: opacity 0.2s linear;
			-o-transition: opacity 0.2s linear;
			transition: opacity 0.2s linear;

			.element-hover-content {
				width: 100%;
				position: absolute;
				left: 0;
				padding: 15px;
				top: 50%;
				transform: translateY(-50%);
				.title {
					font-size: 14px;
					color: #fff;
					font-weight: bold;
					&:after {
						content: "";
						display: block;
						width: 58px;
						height: 4px;
						margin: 13px auto 0 auto;
					}
					&.sciences-sante {
						&:after {
							background-color: $color-scientifique;
						}
					}
					&.ages-societe {
						&:after {
							background-color: $color-societe;
						}
					}
					&.culture {
						&:after {
							background-color: $color-culture;
						}
					}
				}
				.name {
					margin-top: 18px;
					color: #fff;
					font-size: 15px;
				}
			}
		}
	}
}

#projects-container{
	margin-bottom: 80px;
	.header-area{
		display: flex;
		justify-content: space-between;
		a{
			color: #575756;
			text-decoration: none;
			font-size: 18px;
			img{
				width: 28px;
				height: auto;
				margin-left: 15px;
			}
		}
	}
	.section-title:after{
		display: none;
	}
}
