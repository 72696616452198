.single-bourse{
	.back{
		text-transform: uppercase;
		font-weight: 100;
	    background: #b7b7b7;
	    padding: 10px 15px;
	    color: white;
	    margin-bottom: 20px;
	    display: inline-block;
	    &:hover{
	    	text-decoration: none;
	    }
	}
}

.bourse-sidebar {
	padding: 20px;
	&.culture{
		background-color: rgba($color-culture, .4);
	}
	&.sciences-sante{
		background-color: rgba($color-scientifique, .4);
	}
	&.societe{
		background-color: rgba($color-societe, .4);
	}

	.bourse-sidebar-container{
		margin: auto;

		.author-picture {
			display: block;
			position: relative;
	        .icon{
	            background-color: $color-culture;
	            display: inline-block;
	            height: 31px;
	            width: 31px;
	            border-radius: 50%;
	            position: absolute;
	            bottom: 15px;
	            right: 15px;
	            svg{
	                max-height: 60%;
	                max-width: 60%;
	                position: absolute;
	                top: 50%;
	                left: 50%;
	                transform: translate(-50%, -50%);
	                g, path, circle{
	                    fill: white;
	                }
	            }
	        }
			img {
				width: 100%;
			}
		}

		.picture-copyright {
		    font-size: 12px;
		    text-align: right;
		    margin-bottom: 30px;
		}

		.team {
			margin-top: 30px;
			margin-bottom: 30px;
		    .title {
				margin: 0 0 15px 0;
		    }
		    ul {
		        margin: 0;
		        padding: 0;
		        list-style: none;
		        li {
					&:first-child {
						font-weight: bold;
					}
		        }
		    }
		}

	}

	h2{
		font-weight: bold;
		color: #464849;
		font-size: 18px;
		margin-bottom: 40px;
	}

	a {
		font-size: 18px;
		color: #464849;
		font-weight: normal;
		text-decoration: none;

		&:hover {
			color: #222;
			text-decoration: none;
		}
	}

	.website{
		display: block;
		width: 100%;
		background: url(../../images/logo-website.png) no-repeat;
		padding-left: 40px;
	}

	.categories {
		margin: 0;
		padding: 0;
		list-style: none;
		margin-bottom: 10px;

		a {
			display: block;
			width: 100%;
			background: url(../../images/logo-category.png) no-repeat;
			padding-left: 40px;
		}

	}

}

.bourse-quote{
	position: relative;
	padding: 50px 70px 30px 70px;
	p {
		font-size: 18px!important;
	}
	.quote-author{
		margin-top: -20px;
	}

	/* &:first-letter {
		font-size: 22px;
		font-weight: bold;
	} */

	&:before,
	&:after{
		content: '';
		width: 50px;
		height: 40px;
		position: absolute;
		background-repeat: no-repeat!important;
	}

	&:before{
		top: 45px;
		left: 0;
	}

	&:after{
		bottom: 60px;
		right: 0;
	}
	&.sciences-sante-quote {
		&:before{
			background: url(#{$assetsPictures}quote-top_scientifique.png);
		}
	
		&:after{
			background: url(#{$assetsPictures}quote-bottom_scientifique.png);
		}
	}
	&.culture-quote {
		&:before{
			background: url(#{$assetsPictures}quote-top_culture.png);
		}
	
		&:after{
			background: url(#{$assetsPictures}quote-bottom_culture.png);
		}
	}
	&.ages-societe-quote {
		&:before{
			background: url(#{$assetsPictures}quote-top_societe.png);
		}
	
		&:after{
			background: url(#{$assetsPictures}quote-bottom_societe.png);
		}
	}
}

#research-bourse-container {
	position: relative;
    width: 405px;
	display: inline-block;
	float: right;
	position: absolute;
	top: -77px;
	right: 0;
	#bourse-search {
		width: 405px;
		background: none !important;
		border: 0;
		border-bottom: 1px solid #979797;
		font-size: 14px;
		color: #575756;
	}
	#research-bourse-submit {
		position: absolute;
		top: 0;
		right: 0;
		border: 0;
		background: none;
		background-image: url('../../images/glass_black.png');
		width: 13px;
		height: 13px;
		padding-right: 6px;
	}
}

.bourse-related{
	background: #F5F5F5;
	padding-top: 40px;
	padding-bottom: 50px;
	margin-bottom: -60px;

	.container {
		position: relative;
	}

	.section-title {
		margin-top: 20px;
		margin-bottom: 40px;
	}

	.years-elements-row{
		display: none;

		&.active{
			display: block;
		}

		/* &:before {
			content: '';
			display: block;
			position: absolute;
			right: 0px;
			height: 65%;
			width: 100%;
			opacity: 1;
			z-index: 1;
			background-color: red;
			transition: all .4s ease-in;
		} */

	}

	.years-elements{
		position: relative !important;
		display: block;
		height: 220px;

		.years-elements-image,
		.years-elements-infos{
			position: absolute;
			top: 0;
			left: 0;
		}

		.years-elements-infos{
			width: 155px;
			height: 210px;
			display: none;
			background: rgba(0, 0, 0, 0.5);
			text-align: center;
			color: white;
			font-size: 15px;
			padding-top: 100px;
		}

		&:hover{
			.years-elements-infos{
				display: block;
			}

		}
	}
}

@keyframes mymove {
    0% { opacity: 0; top: 7px; }
    5% { opacity: 0.2; top: 7px; }
	10% { opacity: 0.5; top: 7px; }
	15% { opacity: 0.8; top: 7px; }
	20% { opacity: 1; top: 7px; }
	40% { opacity: 0.9; top: 7px; }
	100% { opacity: 0; top: 20px; }
}

._scroll-wrapper {

	margin-top: 20px;

	._scroll {
		display: block;
		position: relative;
		width: 22px;
		height: 32px;
		background: url(../../images/mouse_bourse.svg) no-repeat;
		background-size: 22px 32px;
		margin: auto;
		/* left: -50%; */
		left: 0px;
		i {
			position: absolute;
			top: 7px;
			left: 8px;
			width: 6px;
			height: 11px;
			background: url(../../images/shape_bourse.svg) no-repeat;
			background-size: 6px 8px; 
			animation: mymove 1.2s infinite;
		}
	}
}
