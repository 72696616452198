.blog-post{
	margin-bottom: 70px;
	display: inline-block;
    width: 100%;
    font-size: 15px;
	h1 {
		font-size: 37px;
	}
	h2 {
		font-size: 25px;
	}
  h1, h2{
    font-weight: bold;
    color: #464849;

    a{
      color: #464849;
      text-decoration: none;

      &:hover{
        text-decoration: underline;
      }
    }
  }

  .blog-post-thumbnail{
    position: relative;
    overflow: hidden;

    .background{
    	height: 200px;
    	background-size: cover;
    	background-position: center;
	    transition: all 0.6s ease-in-out;
		transform: scale(1.00);
		&:hover {
			transform: scale(1.03);
		}
    }

    .category {
		display: inline-block;
		color: #fff;
		text-transform: uppercase;
		font-weight: bold;
		font-size: 16px;
		position: absolute;
		top: 0;
		right: 20px;
		line-height: 22px;
		.cat {
			color: white;
			background-color: #383838;
			display: inline-block;
			padding: 7px 14px;
			-webkit-transition: background-color 0.1s linear;
	        -moz-transition: background-color 0.1s linear;
	        -o-transition: background-color 0.1s linear;
	        -ms-transition: background-color 0.1s linear;
	        transition: background-color 0.1s linear;
			&.sciences-sante {
				background-color: $color-scientifique;
				&:hover {
					background-color: lighten($color-scientifique, 5%);
				}
			}
			&.culture, &.presse-culture {
				background-color: $color-culture;
				&:hover {
					background-color: lighten($color-culture, 5%);
				}
			}
			&.ages-societe {
				background-color: $color-societe;
				&:hover {
					background-color: lighten($color-societe, 5%);
				}
			}
			&.age-et-societe {
				background-color: $color-societe;
				&:hover {
					background-color: lighten($color-societe, 5%);
				}
			}
			&.interdomaines, &.interdomaine, &.la-fondation {
				background-color: #383838;
				&:hover {
					background-color: lighten(#383838, 5%);
				}
			}
			&:hover {
				color: white;
				text-decoration: none;
			}
		}
	}

  }

  .blog-post-date{
    font-size: 14px;
    color: #7F8081;
    margin-bottom: 15px;
  }

}

.blog-post-similar{
  background: #F8F8F8;

  a {
	  display: block;
	  img {
		  width: 100%;
	  }
  }

  h5, h5 a{
    color: #464849;
    font-size: 21px;
    font-weight: bold;
    display: block;
    padding: 15px 20px;

    &:hover{
      text-decoration: underline;
    }
  }
}

.category-without-banner{
  font-size: 38px;
  font-weight: 700;
  margin-top: 40px;
  margin-bottom: 40px;
  text-transform: uppercase;
  color: #444;
}

.blog, .category.archive, .single-post, .template-press, .archive.tax-presse-categories{
	.filters{
		margin-top: 40px;
		margin-bottom: 60px;
		.filters-title{
			color: #8D8D8D;
			text-transform: uppercase;
			font-size: 16px;
		}
		.filters-categories{
			margin-top: 15px;
			display: flex;
			width: 100%;
			overflow-x: auto;
			padding-bottom: 20px;
			a{
				color: #383838;;
				text-transform: uppercase;
				text-decoration: none;
				font-weight: bold;
				font-size: 16px;
				padding: 3px 12px;
				transition: .4s all;
				margin: 0 20px;
				position: relative;
				white-space: nowrap;
				&:after{
					content: '';
					width: 1px;
					height: 20px;
					background-color: #8D8D8D;
					position: absolute;
					right: -20px;
					top: 4px;
				}
				&:first-child{
					margin-left: 0;
					padding-left: 0;
				}
				&:last-child:after{
					display: none;
				}
				&:hover, &.active{
					color: white;
					background-color: #383838;
					&.all, &.interdomaines, &.interdomaine, &.la-fondation, &.fondation{ background-color: #383838; }
					&.culture, &.presse-culture { background-color: $color-culture; }
			        &.ages-societe, &.age-et-societe{ background-color: $color-societe; }
			        &.sciences-sante { background-color: $color-scientifique; }
			        &:first-child{
						padding-left: 15px;
					}
				}
			}
		}
	}
	.blog-posts-grid{
	  	display: grid;
	    grid-template-columns: repeat(2, 1fr);
	    grid-gap: 80px 60px;
	    .blog-post{
	    	margin-bottom: 0;
	    }
	}
}
