.morphsearch {
	width: 200px;
	min-height: 40px;
	background: #f1f1f1;
	position: absolute;
	z-index: 10000;
	top: 50px;
	right: 50px;
	-webkit-transform-origin: 100% 0;
	transform-origin: 100% 0;
	-webkit-transition-property: min-height, width, top, right;
	transition-property: min-height, width, top, right;
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.5s;
	-webkit-transition-timing-function: cubic-bezier(0.7,0,0.3,1);
	transition-timing-function: cubic-bezier(0.7,0,0.3,1);
}

.morphsearch.open {
	width: 100%;
	min-height: 100%;
	top: 0px;
	right: 0px;
}

.morphsearch-form {
	width: 100%;
	height: 40px;
	margin: 0 auto;
	position: relative;
	-webkit-transition-property: width, height, -webkit-transform;
	transition-property: width, height, transform;
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.5s;
	-webkit-transition-timing-function: cubic-bezier(0.7,0,0.3,1);
	transition-timing-function: cubic-bezier(0.7,0,0.3,1);
}

.morphsearch.open .morphsearch-form {
	width: 80%;
	height: 160px;
	-webkit-transform: translate3d(0,3em,0);
	transform: translate3d(0,3em,0);
}

.morphsearch-input {
	width: 100%;
	height: 100%;
	padding: 0 10% 0 10px;
	font-weight: 700;
	border: none;
	background: transparent;
	font-size: 0.8em;
	color: #ec5a62;
	-webkit-transition: font-size 0.5s cubic-bezier(0.7,0,0.3,1);
	transition: font-size 0.5s cubic-bezier(0.7,0,0.3,1);
}

.morphsearch-input::-ms-clear { /* remove cross in IE */
    display: none;
}

.morphsearch.hideInput .morphsearch-input {
	color: transparent;
	-webkit-transition: color 0.3s;
	transition: color 0.3s;
}

.morphsearch.open .morphsearch-input {
	font-size: 7em;
}

/* placeholder */
.morphsearch-input::-webkit-input-placeholder {
	color: #c2c2c2;
}

.morphsearch-input:-moz-placeholder {
	color: #c2c2c2;
}

.morphsearch-input::-moz-placeholder {
	color: #c2c2c2;
}

.morphsearch-input:-ms-input-placeholder {
	color: #c2c2c2;
}

/* hide placeholder when active in Chrome */
.gn-search:focus::-webkit-input-placeholder {
	color: transparent;
}

input[type="search"] { /* reset normalize */
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.morphsearch-input:focus,
.morphsearch-submit:focus {
	outline: none;
}

.morphsearch-submit {
	position: absolute;
	width: 80px;
	height: 80px;
	text-indent: 100px;
	overflow: hidden;
	right: 0;
	top: 50%;
	background: transparent url(../img/magnifier.svg) no-repeat center center;
	background-size: 100%;
	border: none;
	pointer-events: none;
	transform-origin: 50% 50%;
	opacity: 0;
	-webkit-transform: translate3d(-30px,-50%,0) scale3d(0,0,1);
	transform: translate3d(-30px,-50%,0) scale3d(0,0,1);
}

.morphsearch.open .morphsearch-submit {
	pointer-events: auto;
	opacity: 1;
	-webkit-transform: translate3d(-30px,-50%,0) scale3d(1,1,1);
	transform: translate3d(-30px,-50%,0) scale3d(1,1,1);
	-webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
	transition: opacity 0.3s, transform 0.3s;
	-webkit-transition-delay: 0.5s;
	transition-delay: 0.5s;
}

.morphsearch-close {
	width: 36px;
	height: 36px;
	position: absolute;
	right: 1em;
	top: 1em;
	overflow: hidden;
	text-indent: 100%;
	cursor: pointer;
	pointer-events: none;
	opacity: 0;
	-webkit-transform: scale3d(0,0,1);
	transform: scale3d(0,0,1);
}

.morphsearch.open .morphsearch-close {
	opacity: 1;
	pointer-events: auto;
	-webkit-transform: scale3d(1,1,1);
	transform: scale3d(1,1,1);
	-webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
	transition: opacity 0.3s, transform 0.3s;
	-webkit-transition-delay: 0.5s;
	transition-delay: 0.5s;
}

.morphsearch-close::before,
.morphsearch-close::after {
	content: '';
	position: absolute;
	width: 2px;
	height: 100%;
	top: 0;
	left: 50%;
	border-radius: 3px;
	opacity: 0.2;
	background: #000;
}

.morphsearch-close:hover.morphsearch-close::before,
.morphsearch-close:hover.morphsearch-close::after {
	opacity: 1;
}

.morphsearch-close::before {
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

.morphsearch-close::after {
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.morphsearch-content {
	color: #333;
	margin-top: 4.5em;
	width: 100%;
	height: 0;
	overflow: hidden;
	padding: 0 10.5%;
	background: #f1f1f1;
	position: absolute;
	pointer-events: none;
	opacity: 0;
}

.morphsearch.open .morphsearch-content {
	opacity: 1;
	height: auto;
	overflow: visible; /* this breaks the transition of the children in FF: https://bugzilla.mozilla.org/show_bug.cgi?id=625289 */
	pointer-events: auto;
	-webkit-transition: opacity 0.3s 0.5s;
	transition: opacity 0.3s 0.5s;
}

.dummy-column {
	width: 30%;
	padding: 0 0 6em;
	float: left;
	opacity: 0;
	-webkit-transform: translate3d(0,100px,0);
	transform: translateY(100px);
	-webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
	transition: transform 0.5s, opacity 0.5s;
}

.morphsearch.open .dummy-column:first-child {
	-webkit-transition-delay: 0.4s;
	transition-delay: 0.4s;
}

.morphsearch.open .dummy-column:nth-child(2) {
	-webkit-transition-delay: 0.45s;
	transition-delay: 0.45s;
}

.morphsearch.open .dummy-column:nth-child(3) {
	-webkit-transition-delay: 0.5s;
	transition-delay: 0.5s;
}

.morphsearch.open .dummy-column {
	opacity: 1;
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

.dummy-column:nth-child(2) {
	margin: 0 5%;
}

.dummy-column h2 {
	font-size: 1em;
	letter-spacing: 1px;
	text-transform: uppercase;
	font-weight: 800;
	color: #c2c2c2;
	padding: 0.5em 0;
}

.round {
	border-radius: 50%;
}

.dummy-media-object {
	padding: 0.75em;
	display: block;
	margin: 0.3em 0;
	cursor: pointer;
	border-radius: 5px;
	background: rgba(118,117,128,0.05);
}

.dummy-media-object:hover,
.dummy-media-object:focus {
	background: rgba(118,117,128,0.1);
}

.dummy-media-object img {
	display: inline-block;
	width: 50px;
	margin: 0 10px 0 0;
	vertical-align: middle;
}

.dummy-media-object h3 {
	vertical-align: middle;
	font-size: 0.85em;
	display: inline-block;
	font-weight: 700;
	margin: 0 0 0 0;
	width: calc(100% - 70px);
	color: rgba(145,145,145,0.7);
}

.dummy-media-object:hover h3 {
	color: rgba(236,90,98,1);
}

/* Overlay */
.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.5);
	opacity: 0;
	pointer-events: none;
	-webkit-transition: opacity 0.5s;
	transition: opacity 0.5s;
	-webkit-transition-timing-function: cubic-bezier(0.7,0,0.3,1);
	transition-timing-function: cubic-bezier(0.7,0,0.3,1);
}

.morphsearch.open ~ .overlay {
	opacity: 1;
}

@media screen and (max-width: 53.125em) {
	.morphsearch-input {
		padding: 0 25% 0 10px;
	}
	.morphsearch.open .morphsearch-input {
		font-size: 2em;
	}
	.dummy-column {
		float: none;
		width: auto;
		padding: 0 0 2em;
	}
	.dummy-column:nth-child(2) {
		margin: 0;
	}
	.morphsearch.open .morphsearch-submit {
		-webkit-transform: translate3d(0,-50%,0) scale3d(0.5,0.5,1);
		transform: translate3d(0,-50%,0) scale3d(0.5,0.5,1);
	}
}

@media screen and (max-width: 60.625em) {
	.morphsearch {
		width: 80%;
		top: 10%;
		right: 10%;
	}
}

#search {
	top: 0px;
	.search-form {
		input[type="text"]{
			width: auto !important;
			background: 0 0 !important;
			color: white !important;
			border-top: 0px !important;
			border-right: 0px !important;
			border-left: 0px !important;
			border-bottom: 1px solid white!important;
			font-size: 20px;
			border-radius: 0px;
			font-size: 95px;
			padding-top: 40px;
			height: fit-content;
			&::placeholder {
				color: white;
			}
		}
		input[type="submit"]{
			background: 0 0 !important;
			color: white;
			text-transform: uppercase;
			font-weight: 300;
			border: 1px solid white !important;
			height: auto;
			width: auto;
			padding: 12px 15px;
			border-radius: 0px;
			font-size: 35px;
			line-height: normal;
			&:hover {
				cursor: pointer;
			}
		}
	}
}
